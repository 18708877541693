import React, { useState, useEffect } from "react";
import css from "./basket.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BasketItem from "../BasketItem/BasketItem";
import { useCart } from "react-use-cart";
import { JWT_TOKEN } from "../../config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Basket = () => {
  const dataBasket = useSelector((state) => state.HomeReducers.basketData);
  const [auth, setAuth] = useState(false);
  const [token, setToken] = useState("");
  const {t} = useTranslation()
  const { isEmpty, items, cartTotal } = useCart();
  let navigate = useNavigate();

  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("token")));
  }, []);

  useEffect(() => {
    localStorage.getItem(JWT_TOKEN) !== null && setAuth(true);
  }, []);

  const handleRedirect = () => {
    navigate("/billing");
  };
  useEffect(() => {
    // console.log = console.warn = console.error = () => {};
    console.clear()
  },[] );
  return (
      <div>
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className={css.wishHome}>
                <AiOutlineHome />
                <span>/</span>
                <p>{t("cart")}</p>
              </div>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div className={css.wishMyList}>
                <h1>{t("cardList")}</h1>
              </div>
            </Col>
            <Col lg={12} md={12} xs={12}>
              {isEmpty ? (
                  <h3>{t("empty")}</h3>
              ) : (
                  <div className={css.table}>
                    <table>
                      <thead>
                      <tr className={css.tr}>
                        <th className={css.tdR}>{t("img")}</th>
                        <th className={css.tdR}>{t("name")}</th>
                        <th className={css.tdR}>{t("price")}</th>
                        <th className={css.tdR}>{t("quaint")}</th>
                        <th className={css.tdR}>{t("tottal")}</th>
                        <th>{t("action")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {items &&
                      items.map((item) => {
                        return <BasketItem itemData={item} key={item.id} />;
                      })}
                      </tbody>
                    </table>
                  </div>
              )}
            </Col>
          </Row>
          <Row className="mt-5 justify-content-md-end">
            <Col lg={12} md={12} xs={12}>
              {isEmpty ? null : (
                  <div className={css.btnAdd}>
                    <div>
                      <h1>{t("tottalPrice")}: {cartTotal} ֏</h1>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p style={{width: 400, margin: 0}}>
                        {t("patver")}
                        </p>
                      {!auth ? (
                          <Link to="/sign-in">
                            <button>{t("signIn")}</button>
                          </Link>
                      ) : (
                          <button onClick={handleRedirect}>{t("buy")}</button>
                      )}
</div>
                    </div>
                  </div>
              )}
            </Col>
          </Row>
        </Container>
        <div className={css.wishBg}></div>
      </div>
  );
};

export default Basket;
