import React from 'react'
import styles from "./loader.module.css";

const Loading = () => {
    return (
      <section className={styles.loader_section}>
    

      <div className={styles.loader}>Loading...</div>
    </section>
    )
}

export default Loading