import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { useCart } from "react-use-cart";
import { Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { Col, Container, Row } from "react-bootstrap";
import { url, JWT_TOKEN, myUrl } from "../../config";
import amia from "../../Utils/images/amia.jpeg";
import css from "./billing.module.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as Visa } from "../../Utils/images/visa.svg";
// import { ReactComponent as Arca } from "../../Utils/images/arca1.svg";"../../Utils/images/arca2.jpeg"
// import Idram from '../../Utils/images/idram2.jpeg';
import Idram from '../../Utils/images/idramStyle.png';
import Arca from '../../Utils/images/arca.svg';
import Swal from "sweetalert2";

// import idram from "../../Utils/images/idram2.png";
// import arca from "../../Utils/images/arca2.png";

const Billing = () => {
  const [paymentType, setPaymentType] = useState("");
  const { t } = useTranslation();
  const [calendarData, setCalendarData] = useState("");
  const router = useNavigate();
  const [timeData, setTimeData] = useState("");
  const [timeDataError, setTimeDataError] = useState(false);
  const [delev, setDelev] = useState(null);
  const [valute, setValute] = useState("051");
  const val = useSelector((state) => state.serviceReducers.valutes);
  const thisValute = val.key;
  const {
    isEmpty,
    cartTotal,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  
  let calendarValue;
  const handlerCalendar = (e) => {
    calendarValue = e.target.value;
    setCalendarData(calendarValue);
  };

  let timeValue;
  const handlertime = (e) => {
    timeValue = e.target.value;
    setTimeData(timeValue);
  };

  const handlerIdram = () => {
    setPaymentType("1");
  };
  useEffect(() => {
    if (thisValute?.key == "price_amd") {
      setValute("051");
    } else if (thisValute?.key == "price_eur") {
      setValute("978");
    } else if (thisValute?.key == "price_usd") {
      setValute("840");
    } else {
      setValute("643");
    }
  }, [thisValute]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validate = Yup.object({
    firstname: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    message: Yup.string()
      .min(3, "Must be 3 characters or less")
      .max(15, "Must be 20 characters or less")
      .required("Required"),
    phonenumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    lastname: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    phonenumber2: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    addres: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
  });

  let basketItems = [];
  items?.forEach((i) => basketItems.push({ id: i.id, quantity: i.quantity }));
  useEffect(() => {
    // console.log = console.warn = console.error = () => {};
    console.clear();
  }, []);
  
  return (
    <div>
      <Container>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <div className={css.home}>
              <span>
                <AiOutlineHome />
              </span>
              <span>/</span>
              <span>{t("check")}</span>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <div className={css.mediaBil}>
              <h3>{t("detal")}</h3>
            </div>
          </Col>

          <Col lg={12} md={12} xs={12} className="mt-3">
            <Formik
              initialValues={{
                email: "",
                lastname: "",
                firstname: "",
                phonenumber: "",
                phonenumber2: "",
                addres: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = t("require");
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = t("require");
                } else if (!values.lastname) {
                  errors.lastname = t("require");
                } else if (!values.firstname) {
                  errors.firstname = t("require");
                } else if (!values.phonenumber) {
		  errors.phonenumber = t("require");
		}
                   return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                //     alert(JSON.stringify(values, null, 2));
                //     setSubmitting(false);
                // }, 400);
                if (paymentType == "") {
                  //   alert("select payment type!");
                } else {
                  if (paymentType == "1") {
                    delev == null ? Swal.fire(t('meekz')) :
                    axios
                      .post(
                        `${url}/api/v1/orders/`,
                        {
                          ClientID: "76718520-9466-4346-aa11-9d03a4d35f4c",
                          Description: "item",
                          Amount: Number(cartTotal) + Number(delev),
                          Delevery: delev,
                          email: values.email,
                          lastname: values.lastname,
                          firstname: values.firstname,
                          phonenumber: values.phonenumber,
                          phonenumber2: values.phonenumber2,
                          addres: values.addres,
                          Currency: "051",
                          products: basketItems,
                          deleveryDate: calendarData,
                          deleveryTime: timeData,
                          type: "idram",
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              JWT_TOKEN
                            )}`,
                          },
                        }
                      )
                      .then((res) => {
                        if (res.status == 200) {
                          window.location.href = `${myUrl}/idram/${res.data.payment_id}`;
                        }
                      })
                      .catch((e) => console.log(e));
                  }
                  if (paymentType == "2") {
                    alert("paypal");
                  }
                  if (paymentType == "3" && Number(cartTotal) !== NaN) {
                    delev == null ? Swal.fire(t('meekz')) :
                    axios
                      .post(
                        `${url}/api/v1/orders/`,
                        {
                          ClientID: "76718520-9466-4346-aa11-9d03a4d35f4c",
                          Description: items?.[0]?.description,
                          Amount: Number(cartTotal) + Number(delev),
                          Delevery: delev,
                          email: values.email,
                          lastname: values.lastname,
                          firstname: values.firstname,
                          phonenumber: values.phonenumber,
                          phonenumber2: values.phonenumber2,
                          addres: values.addres,
                          Currency: "051",
                          products: basketItems,
                          deleveryDate: calendarData,
                          deleveryTime: timeData,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              JWT_TOKEN
                            )}`,
                          },
                        }
                      )
                      .then(function (response) {
                        const { data } = response;
                        const { PaymentID, ResponseCode } = data;

                        if (ResponseCode == 1 && PaymentID) {
                          emptyCart();
                          window.location.href = `https://services.ameriabank.am/VPOS/Payments/Pay?id=${PaymentID}&lang=am`;
                        } else {
                          alert("error");
                        }
                      })
                      .catch(function (error) {
                        console.log(error, "error");
                      });
                  }
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row className="justify-content-md-center">
                    <Col lg={6} md={12} xs={12}>
                      <div className={css.divOne}>
                        <div className={css.nameInp}>
                          <div className={css.divOneOne}>
                            <div>
                              <h4>{t("fName")}</h4>
                              <input
                                type="text"
                                name="firstname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstname}
                              />
                              {errors.firstname && touched.firstname && (
                                <div className={css.errorEmail}>
                                  {errors.firstname}
                                </div>
                              )}
                            </div>

                            <div>
                              <h4>{t("lName")}</h4>
                              <input
                                type="text"
                                name="lastname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastname}
                              />
                              {errors.lastname && touched.lastname && (
                                <div className={css.errorEmail}>
                                  {errors.lastname}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className={css.nameInp}>
                          <div className={css.divOneOne}>
                            <div>
                              <h4>{t("number")}</h4>
                              <input
                                type="tel"
                                name="phonenumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phonenumber}
                              />
                              {errors.phonenumber && touched.phonenumber && (
                                <div className={css.errorEmail}>
                                  {errors.phonenumber}
                                </div>
                              )}
                            </div>

                            <div>
                              <h4>{t("number")}</h4>
                              <input
                                type="tel"
                                name="phonenumber2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phonenumber2}
                              />
                              {errors.phonenumber2 && touched.phonenumber2 && (
                                <div className={css.errorEmail}>
                                  {errors.phonenumber2}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className={css.mainInp}>
                          <div>
                            <h4>{t("mail")}</h4>
                            <input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email && touched.email && (
                              <div className={css.errorEmail}>
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div>
                            <div className={css.data}>
                              <div className={css.calendarText}>
                                <h4>{t("date")}:</h4>
                              </div>

                              <div className={css.dateInp}>
                                <input type="date" required min={new Date().toISOString().slice(0, -14)} onChange={handlerCalendar} />
                                {timeDataError == true ? (
                                  <h5 className={css.err}>required</h5>
                                ) : null}
                              </div>
                            </div>

                            <div className={css.data}>
                              <div className={css.calendarText}>
                                <h4>{t("time")}:</h4>
                              </div>
                              <div className={css.dateInp}>
                                <select
                                  name="Time"
                                  id="cars"
                                  onChange={handlertime}
                                >
                                  <option value="09:00">09:00</option>
                                  <option value="10:00">10:00</option>
                                  <option value="11:00">11:00</option>
                                  <option value="12:00">12:00</option>
                                  <option value="13:00">13:00</option>
                                  <option value="14:00">14:00</option>
                                  <option value="15:00">15:00</option>
                                  <option value="16:00">16:00</option>
                                  <option value="17:00">17:00</option>
                                  <option value="18:00">18:00</option>
                                  <option value="19:00">19:00</option>
                                  <option value="20:00">20:00</option>
                                  <option value="21:00">21:00</option>
                                  <option value="22:00">22:00</option>
                                  <option value="23:00">23:00</option>
                                  <option value="24:00">24:00</option>
                                </select>
                                {timeDataError == true ? (
                                  <h5 className={css.err}>required</h5>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div>
                            <h4>{t("addd")}</h4>
                            <input
                              type="text"
                              name="addres"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.addres}
                            />
                            {errors.addres && touched.addres && (
                              <div className={css.errorEmail}>
                                {errors.addres}
                              </div>
                            )}
                          </div>
                          <div>
                            <h4>{t("pay")}</h4>
                            <Field
                              required
                              as="select"
                              name="del"
                              value={delev}
                              onChange={(e) => setDelev(e.target.value)}
                            >
                              <option placeholder="ss" value="1000">{t("chose")}</option>
                              <option value="1000">Երևան-1000</option>
                              <option value="2500">Աբովյան-2500</option>
                              <option value="3000">Արտաշատ-3000</option>
                              <option value="4000">Արարատ-4000</option>
                              <option value="3500">Էջմիածին-3500</option>
                              <option value="22000">Դիլիջան-22000</option>
                              <option value="19000">Ծախկաձոր-19000</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={12} xs={12} className="mt-5">
                      <div className={css.divTwo}>
                        <div className={css.paymentMain}>
                          <div className={css.paymentProduct}>
                            <h5>{t("pro")}</h5>
                            <div />
                          </div>
                          <div>
                            <div className={css.divPrice}>
                              <h5>{t("price")}</h5>
                              <div  className={css.prodName}>
                                {items &&
                                  items.map((item) => {
                                    return (
                                      < >
                                        <h5>{item.itemTotal} ֏ </h5>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className={css.border} />
                          </div>

                          <div>
                            <div className={css.prodTotal}>
                              <p>{t("tottal")}</p>
                              {/*<h5>{(Number(cartTotal) + Number(delev)) == NaN ? null :  Number(cartTotal) + Number(delev) }</h5>*/}
                              <h5>
                                {delev
                                  ? Number(cartTotal) + Number(delev)
                                  : Number(cartTotal)}{" "}
                                ֏
                              </h5>
                            </div>
                            <div className={css.border} />
                          </div>

                          {/* <div className={css.carts}>
                            <p>{t("payType")}</p>
                            <div className={css.imagesCart}>
                              <div className={css.paymentsBox}>
                                <div>
                                  <h4>{t("idram")}</h4>
                                </div>
                                <button
                                  onClick={() => setPaymentType("1")}
                                  className={css.payBtns}
                                >
                                  <img
                                    src={idram}
                                    alt="Picture of the author"
                                    width={70}
                                    height={20}
                                  />
                                </button>
                              </div>

                              <div className={css.onlintBox}>
                                <div>
                                  <div className={css.paymentsBox}>
                                    <div>
                                      <h4>{t("banks")}</h4>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    onClick={() => setPaymentType("3")}
                                    className={css.payBtns}
                                  >
                                    <div className={css.visas}></div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className={css.carts_items_block}>



                            <span className={css.title_carts}>
                              <p>{t("payType")}</p>
                            </span>


                            <div className={css.visa}>
                              <div className={css.img_block}>
                                <button onClick={() => setPaymentType("3")}>
                                  <Visa />
                                  {/* <Arca /> */}
                                  <img style={{width:"80px",height:"60px"}} src={Arca}/>
                                </button>
                              </div>
                              <span className={css.text}>
                                <p>{t("banks")}</p>
                              </span>
                            </div>


                            <div className={css.idram}>
                              <button onClick={handlerIdram}>
                                <img src={Idram} alt="Picture of the author" />
                              </button>
                              <p>{t("idram")}</p>
                            </div>



                          </div>
                        </div>
                      </div>
                    </Col>

                    <div className={css.btn}>
                      {/*<button type="submit" disabled={isSubmitting}>*/}
                      {/*    Submit*/}
                      {/*</button>*/}
                    </div>
                  </Row>
                </form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Billing;
