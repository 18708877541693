import axios from "axios";
import {url} from '../../config'

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const CHANGE_VALUE = "CHANGE_VALUE"

export const getAllProducts = (data) => {
  return async (dispatch) => {
    let queryObj = {
      page: data.page,
      price_type: data.thisValute
    }
    if(data.category) queryObj['category'] = data.category
    if(data.min_price) queryObj['min_price'] = data.min_price
    if(data.max_price) queryObj['max_price'] = data.max_price

    const response = await axios.get(`${url}/api/v1/product/`, {
      params: queryObj,
    });
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: response.data,
    });
  };
};



export const changeValueteAC = (data) => {
  return {
    type: CHANGE_VALUE,
    payload: data
}
}