import { GET_ABOUT_BANNER, GET_ABOUT_INFO, GET_CATEGORIES, GET_HOME_BANNER, GET_SHORT_ABOUT, HOME_ITEMS } from "../Actions/banersAction";

const initialState = {
  categories: [],
  homeBaner: {},
  items:[],
  aboutInfo : null,
  aboutBanner:null,
  shortAbout:null
};

export const banerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_HOME_BANNER:
      return {
        ...state,
        homeBaner: action.payload[0],
      };
    case HOME_ITEMS:
      return {
          ...state,
          items:action.payload
      };
      case GET_ABOUT_INFO:
      return {
        ...state,
        aboutInfo:action.payload
      }
      case GET_ABOUT_BANNER:
        return {
          ...state,
          aboutBanner:action.payload
        }
        case GET_SHORT_ABOUT:
          return {
            ...state,
            shortAbout:action.payload
          }
    default:
      return state;
  }
};
