import React, { useEffect, useState } from "react";
import css from "./homeTouch.module.css";
import { Button, Col, Container, Modal, NavLink, Row,Form } from "react-bootstrap";
import { GrFacebookOption } from "react-icons/gr";
import { BsInstagram } from "react-icons/bs";
import * as Yup from "yup";
import * as Formik from "formik";
import { url } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { getContactUsInfoThunk } from "../../../Redux/Actions/banersAction";
import axios from "axios";
import touchImg from "../../../Utils/images/touchImg.svg"
import { useTranslation } from "react-i18next";


const HomeTouch = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const info = useSelector((state) => state.banerReducer.aboutInfo);
  useEffect(()=>{
    dispatch(getContactUsInfoThunk())
  },[])

  const {t} = useTranslation()
  const validate = Yup.object({
    name: Yup.string()
      .max(25, "Must be 25 characters or less")
      .required("Required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    message: Yup.string()
      .min(3, "Must be 3 characters or less")
      .max(60, "Must be 60 characters or less")
      .required("Required"),
  });

  // useEffect(() => {
  //   dispatch(getContactUsInfoThunk());
  // }, []);

  return (
    <Container>
      <Row>
        <Col lg={6} md={6} xs={12}>
          <div className={css.mainTouch}>
            <h1>{t("tuch")}</h1>
            <Formik.Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
              validationSchema={validate}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .post(`${url}/api/v1/contact/email`, {
                    name: values.name,
                    email: values.email,
                    phone: "values.phonenumber",
                    message: values.message,
                  })
                  .then(function (response) {
                  //   if(response.data.succsess){
                  //     Swal.fire({
                  //         position: 'center',
                  //         icon: 'success',
                  //         showConfirmButton: false,
                  //         timer: 1500
                  //       })
                  // }
                  setShow(true);
                  values.name = "";
                  values.email = "";
                  values.message = "";
                  })
                  .catch(function (error) {
                    console.error('error',error)
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                /* and other goodies */
              }) => (
               <Formik.Form onSubmit={handleSubmit}>
                <div className="mt-5 mb-5">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="name"
                      name="name"
                      placeholder={`${t("fName")}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      className={css.inpName}
                    />
                    <span className={css.err}>{errors.name && touched.name && errors.name}</span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={`${t("mail")}`}
                      className={css.inpName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className={css.err}>{errors.email && touched.email && errors.email}</span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="name"
                      name="message"
                      placeholder={`${t("typeMessage")}`}
                      className={css.inpTxterea}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                    <span className={css.err}>{errors.message && touched.message && errors.message}</span>
                  </Form.Group>
                  <Button type="submit" className={css.btnSend}>
                    {t("send")}
                  </Button>
                </div>
               </Formik.Form>
              )}
            </Formik.Formik>

            <div className={css.icons}>
              <h3>{t("follow")}:</h3>
              <a href={info && info.facebook} target="blank">
                <GrFacebookOption className={css.iconFb} />
              </a>
              <a href={info && info.instagram} target="blank">
                <BsInstagram className={css.iconInsta} />
              </a>
            </div>
          </div>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <div className={css.touchImg}>
            <img
              src={touchImg}
              alt="Picture of the author"
              width={300}
              height={500}
            />
          </div>
        </Col>
      </Row>

      {show ? (
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <i class="fa-solid fa-circle-check m-2 text-success">
                          Success
                        </i>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("modalText")}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                      {t("closeBtn")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                ) : null}
    </Container>
  );
};

export default HomeTouch;
