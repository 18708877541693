import React, {useEffect, useState} from 'react';
import css from './homeFloral.module.css'
import {Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {basketDataGet, getProduct, swishDataGet, swishRemoveDataGet} from "../../../Redux/Actions/productActions";
import {Link} from 'react-router-dom'
import HomeItem from "../HomeItem";
import { homeItemsAc } from '../../../Redux/Actions/banersAction';
import { useTranslation } from 'react-i18next';

const HomeFloral = () => {

    const data = useSelector(state => state.HomeReducers.productData)
    const products = useSelector((state) => state?.banerReducer.items);
    const dispatch = useDispatch()
    const {t} = useTranslation()
    useEffect(() => {
        dispatch(getProduct())
        dispatch(homeItemsAc())
    }, [])


    return (
        <div>
            <div className={css.FloralText}>
                <div/>
                <h1 className={css.floralH1}>{t("floral")}</h1>
                <div/>
            </div>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={10} md={10} xs={12}>
                        <div className={css.floralTextP}>
                            <p>{t("matter")} </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="justify-content-md-center mt-5">

                    {
                        products && products.map((item) => {
                            return (
                                <HomeItem item={item} key={item.id}/>
                            )
                        })
                    }
                    <Col className="justify-content-md-center mb-3">
                        <Link className={css.productSee} to="/products">
                            {t("more")}
                        </Link>
                    </Col>


                </Row>
            </Container>


        </div>
    );
};

export default HomeFloral;