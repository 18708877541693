import React, {useEffect, useState} from "react";
import css from "./signUp.module.css";
import {FaEye} from "react-icons/fa";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {url} from "../../config";
import {Formik} from "formik";
import {LOGIN_PAGE} from "../../Routing/urls";
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'

const SignUp = () => {
    const router = useNavigate();
    const [icon, setIcon] = useState();
    const [icon2, setIcon2] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirm, setConfirm] = useState();
    //loginValues
    const {t} = useTranslation()
    const validate = Yup.object({
        email: Yup.string().email("Email is invalid").required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 charaters")
            .required("Password is required"),
        changepassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Password must match")
            .required("Confirm password is required"),
    });

    const handlerIcon = () => {
        setIcon(!icon);
    };

    const handlerIcon2 = () => {
        setIcon2(!icon2);
    };

    const handleSignUp = () => {
        if (password !== confirm) {
            alert("password and confirm is not mutch!");
        } else {

        }
    };

    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    }, []);

    return (
        <div className={css.loginMain}>
            <div className={css.loginOne}>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        changepassword: "",
                    }}
                    validationSchema={validate}
                    onSubmit={(values) => {
                        if (password !== confirm) {
                            alert("password and confirm is not mutch!");
                            Swal.fire('Any fool can use a computer')
                        } else {
                            axios
                                .post(`${url}/api/v1/auth/register`, {
                                    email: values.email,
                                    password: values.password,
                                })
                                .then(function (response) {
                                    if (response.data.err) {
                                        Swal.fire(t("exist"))
                                    } else {

                                        router(LOGIN_PAGE);
                                    }
                                })
                                .catch(function (error) {
                                });
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => (
                        <div className={css.login1}>
                            <div className={css.logintext}>
                                <h1>{t("signUp")}</h1>
                            </div>

                            <div className={css.loginInp}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={`${t("mail")}`}
                                    onChange={handleChange}
                                    // onChange={(e) => setEmail(e.target.value)}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    required
                                />
                                {errors.email &&
                                touched.email &&
                                t('require')}
                                <div className={css.inpIcon}>
                                    <input
                                        type={!icon ? "password" : "text"}
                                        placeholder={`${t("password")}`}
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        // onChange={(e) => setPassword(e.target.value)}
                                        // onBlur={handleBlur}
                                        value={values.password}
                                        required
                                    />
                                    {errors.password &&
                                    touched.password &&
                                    t('require')}

                                    <span onClick={handlerIcon}>
                    <FaEye/>
                  </span>
                                </div>
                                <div className={css.inpIcon}>
                                    <input
                                        type={!icon2 ? "password" : "text"}
                                        placeholder={`${t("confirm")}`}
                                        name="changepassword"
                                        onChange={handleChange}
                                        // onChange={(e) => setConfirm(e.target.value)}
                                        onBlur={handleBlur}
                                        value={values.changepassword}
                                        required
                                    />
                                    {errors.changepassword &&
                                    touched.changepassword &&
                                    t('require')}

                                    <span onClick={handlerIcon2}>
                    <FaEye/>
                  </span>
                                </div>
                            </div>
                            {/* //onClick={handleSignUp} */}
                            <button
                                onClick={handleSubmit}
                                // onClick={handleSignUp}
                                className={css.btnOne}>
                                {t("signUp")}
                            </button>
                            <span
                                className={css.loginSpan}
                            >
                <Link to={"/sign-in"}>
                  {t("signIn")}
                </Link>
              </span>
                        </div>
                    )}
                </Formik>
            </div>

            <div className={css.loginTwo}>
                <div className={css.loginBg}></div>
            </div>
        </div>
    );
};

export default SignUp;
