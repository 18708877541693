import React, {useEffect, useState} from 'react';
import css from './whisItem.module.css';
import {swishRemoveDataGet} from "../../Redux/Actions/productActions";
import {useDispatch, useSelector} from "react-redux";
import { ImageList } from '@mui/material';
import close from "../../Utils/images/close.svg"
const WhisItem = ({itemDataWhis}) => {

    const dispatch = useDispatch()
    const locale = useSelector(state => state.langReducer.lang)
    const val = useSelector((state) => state.serviceReducers.valutes);
    const thisValute = val.key;
    const [img, setImage] = useState()

    const removeItem = (id) => {
        dispatch(swishRemoveDataGet(id))
    }

    useEffect(() => {
        setImage(itemDataWhis.images?.substring(0, itemDataWhis.images.length )?.split(",")[0]);
    }, [])
    let dataMain;
    if (typeof window !== 'undefined') {
        dataMain = JSON.parse(localStorage.getItem('swish'))
    } else {
    }


    return (
        <tr key={itemDataWhis.id} className={css.tr}>
            <>
                <td className={css.tdR}><img className={css.thImg}
                                             src={img} alt="asd"/></td>
                <td key={itemDataWhis.id} className={css.tdR}>
                    {locale == "am" && <h5>{itemDataWhis.titleHy}</h5>}
                    {locale == "ru" && <h5>{itemDataWhis.titleRu}</h5>}
                    {locale == "en" && <h5>{itemDataWhis.titleEn}</h5>}
                </td>
                <td key={itemDataWhis.id} className={css.tdR}>
                    {thisValute == "price_eur" && <h6>{itemDataWhis.price_eur} EUR</h6>}
                    {thisValute == "price_ru" && <h6>{itemDataWhis.price_rub} RUB</h6>}
                    {thisValute == "price_amd" && <h6>{itemDataWhis.price_amd} ֏</h6>}
                    {thisValute == "price_usd" && <h6>{itemDataWhis.price_usd} USD</h6>}
                </td>
                <td key={itemDataWhis.id}>
                    <div className={css.icons}>
                        <div className={css.iconsFlex}>
                            <img className={css.iconsFlexImg} src={close}
                                 width="30" height="30"
                                 onClick={() => removeItem(itemDataWhis.id)}/>
                        </div>
                    </div>
                </td>
            </>

        </tr>
    );
};

export default WhisItem;