import React, {useEffect, useState} from 'react';
import { Button, Col, Container, Modal, NavLink, Row } from "react-bootstrap";
import css from "./contactUs.module.css";
import {AiOutlineHome} from "react-icons/ai";
import {Formik} from 'formik';
import {Link} from "@mui/material";
import ContactMain from '../../Utils/images/contactMain.svg'
import ContactMail from '../../Utils/images/contactEmail.svg'
import ContactPhone from '../../Utils/images/contactPhone.svg'
import ContactLocation from '../../Utils/images/contactLocation.svg'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getContactUsInfoThunk} from "../../Redux/Actions/banersAction";
import axios from "axios"
import {url} from "../../config"

const ContactComponents = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const dispatch = useDispatch()
    const info = useSelector((state) => state.banerReducer.aboutInfo);
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(getContactUsInfoThunk())
    }, [])



    useEffect(() => {

        console.clear()
    },[] );

    
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className={css.wishHome}>
                            <Link href="/">
                                <AiOutlineHome/>
                            </Link>
                            <span>/</span>
                            <p>{t("Contact")}</p>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col lg={6} md={6} xs={12}>
                        <div className={css.contact}>
                            <h1>{t("contacts")}</h1>
                            <h2>{t("typeMessage")}</h2>
                        </div>
                        <Formik
                            initialValues={{
                                email: '',
                                name: '',
                                phonenumber: '',
                                message: ''
                            }}
                            validate={values => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email = 'Required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                } else if (!values.name) {
                                    errors.name = 'Invalid email lastname';
                                } else if (!/^[+374]{4}|[0]{1}?[1-9]{2}\d{6}$/i.test(values.phonenumber)) {
                                    errors.phonenumber = 'Invalid email phonenumber';
                                } else if (!values.message) {
                                    errors.message = 'Invalid email message';
                                }
                                return errors;
                            }}
                            onSubmit={(values, {setSubmitting}) => {
                                axios.post(`${url}/api/v1/contact/email`, {
                                    name: values.name,
                                    email: values.email,
                                    phone: values.phonenumber,
                                    message: values.message
                                })
                                    .then(function (response) {
                                        console.log(response);
                                           setShow(true);
                                           values.name = "";
                                           values.email = "";
                                           values.phonenumber = "";
                                           values.message = "";
                                       
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                                setTimeout(() => {
                                    // alert(JSON.stringify(values, null, 2));
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  /* and other goodies */
                              }) => (
                                <form onSubmit={handleSubmit} className={css.formik}>
                                    <input
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        id="outlined-required"
                                        label="Your name"
                                        defaultValue="John Cupper"
                                        placeholder={t("name")}
                                        required
                                    />
                                    {errors.name && touched.name && errors.name}
                                    <input
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        id="outlined-required"
                                        label=""
                                        placeholder={t("mail")}
                                        defaultValue="JohnCupper@gmail.com"
                                        required
                                    />
                                    {errors.email && touched.email && errors.email}
                                    <input
                                        type="number"
                                        name="phonenumber"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phonenumber}
                                        id="outlined-required"
                                        label=""
                                        placeholder={t("phone")}
                                        defaultValue="+374-00-00-00"
                                        required
                                    />
                                    {errors.phonenumber && touched.phonenumber && errors.phonenumber}
                                    <input
                                        type="text"
                                        name="message"
                                        onChange={handleChange}
                                        onBlur={handleBlur} s
                                        value={values.message}
                                        id="outlined-required"
                                        label=""
                                        defaultValue=" "
                                        className={css.ll}
                                        placeholder={t("typeMessage")}
                                        required
                                    />
                                    {errors.message && touched.message && errors.message}
                                    <button type="submit" disabled={isSubmitting} className={css.btn}>
                                        {t("send")}
                                    </button>
                                </form>
                            )}
                        </Formik>
                    </Col>

                    <Col lg={6} md={6} xs={12}>
                        <div>
                            <div className={css.posDiv}>
                                <img
                                    className={css.myImg}
                                    src={ContactMain}
                                    alt="Picture of the author"
                                    width={600}
                                    height={600}
                                />
                                <div className={css.absalute}>
                                    <div className={css.one}>
                                        <img
                                            src={ContactMail}
                                            alt="Picture of the author"
                                            width={40}
                                            height={40}
                                        />
                                        <h5>{info && info.email}</h5>
                                    </div>
                                    <div className={css.two}>
                                        <img
                                            src={ContactPhone}
                                            alt="Picture of the author"
                                            width={40}
                                            height={40}
                                        />
                                        <h5>{info && info.phone}</h5>
                                    </div>
                                    <div>
                                        <img
                                            src={ContactLocation}
                                            alt="Picture of the author"
                                            width={40}
                                            height={40}
                                        />
                                        <h5>{info && info.address}</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>

            {show ? (
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <i class="fa-solid fa-circle-check m-2 text-success">
                          Success
                        </i>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("modalText")}</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                      {t("closeBtn")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                ) : null}


            <div className={css.wishBg}>

            </div>
        </div>
    );
};

export default ContactComponents;