import React, {useEffect, useState} from "react";
import css from "./login.module.css";
import {FaEye} from "react-icons/fa";
import axios from "axios";
import {useNavigate,Link} from "react-router-dom";
import * as Yup from "yup";
import * as Formik from "formik";
import {url, JWT_TOKEN, adminUrl, myUrl} from '../../config'
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

const SignIn = () => {
    const router = useNavigate();
    const [icon, setIcon] = useState();
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loginError, setLoginError] = useState(false)
    const {t} = useTranslation()
    const validate = Yup.object({
        email: Yup.string().email("Email is invalid").required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 charaters")
            .required("Password is required"),
    });

    const handlerIcon = () => {
        setIcon(!icon);
    };

    const handleSubmit = () => {
        axios
            .post(`${url}/api/v1/auth/login`, {
                email,
                password,
            })
            .then(function (response) {
                localStorage.setItem(
                    JWT_TOKEN,
                    response.data.token
                );
                if(response.status == "404"){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                      })
                }

                if (response.data.user.role) {
                    // location.href='http://75.119.142.57:3001'
                    window.location.href = `${adminUrl}/test/${response.data.token}`
                    // location.href = `http://75.119.142.57:3001/test/${response.data.token}`
                    // location.href = `http://75.119.142.57:3001/test/${response.data.token}`
                } else {

                    window.location.href = `${myUrl}`;
                    // router("/")
                }
                ;
            })
            .catch(function (error) {
                setLoginError(true)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                  })
                // setTimeout(() => {
                //     setLoginError(false)
                // }, 3000)
                console.log('error', error)
            });
    }

    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    },[] );

    return (
        <div className={css.loginMain}>
            <div className={css.loginOne}>
                <Formik.Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={validate}
                    onSubmit={(values) => {

                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          // handleSubmit,
                      }) => (
                        <div className={css.login1}>
                            <div className={css.logintext}>
                                <h1>{t("signIn")}</h1>
                                <h3>
                                    <span>Аvenue des Fleurs</span>
                                </h3>
                            </div>
                            {/* {
                                loginError
                                    ? <div className={css.errorAlert}>Invalid data</div>
                                    : null
                            } */}

                            <div className={css.loginInp}>
                                <input
                                    type="email"
                                    placeholder={`${t("mail")}`}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    name="email"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.email}
                                />
                                {errors.email && touched.email && errors.email}
                                <div className={css.inpIcon}>
                                    <input
                                        type={!icon ? "password" : "text"}
                                        placeholder={`${t("password")}`}
                                        name="password"
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        // value={values.password}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    {errors.password && touched.password && errors.password}
                                    <span onClick={handlerIcon}>
                    <FaEye/>
                  </span>
                                </div>
                            </div>


                            <button onClick={handleSubmit}>{t("signIn")}</button>

                            <div className={css.btnDivSignUp}>
                                               <span className={css.loginSpan}>
                                           <Link to="/sign-up">{t("signUp")}</Link>
                                          </span>
                            </div>

                        </div>
                    )}
                </Formik.Formik>
            </div>

            <div className={css.loginTwo}>
                <div className={css.loginBg}></div>
            </div>
        </div>
    );
};

export default SignIn;
