import {combineReducers} from "redux";
import {HomeReducers} from "./Reducers/homeReducers";
import {serviceReducers} from "./Reducers/serviceReducers";
import {aboutReducer} from "./Reducers/aboutRducer"
import {banerReducer} from "./Reducers/banerReducer";
import {langReducer} from "./Reducers/langReducer"
import {myOrders} from "./Reducers/myOrdersReducer";
export const rootReducer = combineReducers({
    HomeReducers,
    serviceReducers,
    aboutReducer,
    banerReducer,
    langReducer,
    myOrders
});
