import React, {useEffect, useState} from "react";
import css from "./product.module.css";
import {Col, Container, Row} from "react-bootstrap";
import {BiHomeAlt} from "react-icons/bi";
import {RiFilterOffLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filterDataGet, getProduct} from "../../Redux/Actions/productActions";
import {useCart} from "react-use-cart";
import ProductItem from "../ProductItem/Item";
import {getAllProducts} from "../../Redux/Actions/productAAction";
import {getBanerCategoriesThunk} from "../../Redux/Actions/banersAction";
import {t} from "i18next";
import Loading from "../Loading";

const Product = () => {
    const {addItem} = useCart();
    const dispatch = useDispatch();
    const [filterIcon, setFilterIcon] = useState();
    const data = useSelector((state) => state.HomeReducers.productData);
    const products = useSelector((state) => state?.HomeReducers.products);
    let dataFilter = useSelector((state) => state.HomeReducers.filterData);
    const val = useSelector((state) => state.serviceReducers.valutes);
    const thisValute = val.key;
    const categories = useSelector((state) => state.banerReducer.categories);
    const locale = useSelector((state) => state.langReducer.lang);
    const [page, setPage] = useState(1);
    const [category, setCategory] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [currentCategorie, setCurrentCategorie] = useState("");

    useEffect(() => {
        console.log(typeof maxPrice, maxPrice, 'max')
        dispatch(
            getAllProducts({
                page,
                min_price: JSON.stringify(minPrice),
                max_price: maxPrice === 0 ? undefined : JSON.stringify(maxPrice),
                thisValute,
                category,
            })
        );
    }, [page, minPrice, maxPrice, thisValute,category]);

    const handleFilter = () => {
        dispatch(
            getAllProducts({
                page,
                min_price: JSON.stringify(minPrice),
                max_price: JSON.stringify(maxPrice),
                thisValute,
                category,
            })
        );
    }

    useEffect(() => {
        dispatch(getProduct());
    }, []);

    useEffect(() => {
        dispatch(getBanerCategoriesThunk());
    }, []);

    const handlerFilterIcon = () => {
    };

    const [fromM, setFromM] = useState(100);
    const [toM, setToM] = useState(10000);
    const [arrangementM, setarrangementM] = useState("");

    let from;
    const addSelectFrom = (e) => {
        from = e.target.value;
        setFromM(from);
        setMinPrice(Number(e.target.value));
    };

    let to;
    const addSelectTo = (e) => {
        to = e.target.value;
        setToM(to);
        setMaxPrice(Number(e.target.value));
    };


    let arrangement;
    const drop1 = (e) => {
        arrangement = e.target.value;
        setarrangementM(arrangement);
        setCategory(e.target.value);
    };

    const dataMain = {
        mainTo: toM,
        froMain: fromM,
        arrangementMain: arrangementM,
    };

    const addFilter = () => {
        dispatch(filterDataGet(dataMain));
        setFilterIcon(!filterIcon);
    };

    const addFilter2 = () => {
        setFromM("");
        setToM("");
        setarrangementM("");
        dataFilter.length = 0;
        setFilterIcon(!filterIcon);
    };

    const [sliceData, setSliceData] = useState([]);

    const handlerSee = () => {
        const xData = products && products.length && products;
        setSliceData(
            xData.slice(8).map((item) => {
                return (
                    <Col key={item.id} lg={4} md={4} xs={12}>
                        <ProductItem key={item.id} item={item}/>
                        {/*<div className={css.mainProduct}>*/}
                        {/*    <Link href="/Product">*/}
                        {/*        <div className={css.productDiv}>*/}
                        {/*            <img*/}
                        {/*                src={item.productImage1}*/}
                        {/*                alt=""*/}
                        {/*                height={300}*/}
                        {/*                width={290}*/}
                        {/*            />*/}
                        {/*            <div className={css.productPrice}>*/}
                        {/*                <p>{item.price} $</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*    <div className={css.productText}>*/}
                        {/*        <h2>{item.productName}</h2>*/}
                        {/*        <h3>Medium arrangement</h3>*/}
                        {/*    </div>*/}
                        {/*    <div className={css.productClick}>*/}
                        {/*        <Image src="/basket.svg" width="20" height="20"/>*/}
                        {/*        <Image*/}
                        {/*            src="/love.svg"*/}
                        {/*            width="20"*/}
                        {/*            height="20"*/}
                        {/*            onClick={() => addItem(p)}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Col>
                );
            })
        );
    };

    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    },[] );

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={12} md={12} xs={12} className="mt-2">
                        <div className={css.productHome}>
                            <Link to="/">
                                <BiHomeAlt/>
                            </Link>
                            <span>/</span>
                            <p>{t("flowers")}</p>
                        </div>
                    </Col>

                    <Col lg={8} md={12} xs={12}>
                        <div className={css.main}>
                            <div className={css.main_sub_block}>
                                <h2 className={css.sort3}>{t("byPrice")}</h2>
                                <div className={css.sort}>
                                    <div className={css.sortFlexMain}>
                                        <div>
                                            <p>{t("from")}</p>
                                            <input
                                                type="number"
                                                name="From"
                                                // value={fromM}
                                                placeholder={`${t("min")}`}
                                                onChange={addSelectFrom}
                                            />
                                        </div>

                                        <div>
                                            <p>{t("to")}</p>
                                            <input
                                                type="number"
                                                name="To"
                                                // value={toM}
                                                placeholder={`${t("max")}`}
                                                onChange={addSelectTo}
                                            />
                                        </div>

                                        <div className={css.mainSelect}>
                                            <p>{t("asortiment")}</p>
                                            <div className={css.drop}>
                                                <select
                                                    name="cars"
                                                    id="cars"
                                                    defaultValue={0}
                                                    className={css.select}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                >
                                                    <option value={[]}>
                                                        {t("categorie")}
                                                    </option>
                                                    {categories &&
                                                        categories.map((i) => {
                                                            return (
                                                                <option value={i.id} key={i.id}>
                                                                    {locale == "am" && <p>{i.categoryHy}</p>}
                                                                    {locale == "ru" && <p>{i.categoryRu}</p>}
                                                                    {locale == "en" && <p>{i.categoryEn}</p>}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            {/*<button onClick={handleFilter}>Filter</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-md-start mt-5">
                        {
                          products.length == 0 ? <Loading /> :  products?.map((item) => {
                                return <ProductItem key={item.id} item={item}/>;
                            })
                        }
                    <Col lg={12} md={12} xs={12}>

                        <p className={css.productSee} onClick={() => setPage(page + 1)}>
                            {t("more")}
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Product;
