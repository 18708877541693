import { CHAGE_CURRENT_LANG } from "../Actions/langAction";

const initialState = {
  lang: "am",
};

export const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAGE_CURRENT_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};
