import React, {useState} from "react";
import css from "./basketItem.module.css";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {basketFilterDataGet} from "../../Redux/Actions/productActions";
import {useDispatch, useSelector} from "react-redux";
import {useCart} from "react-use-cart";
import {Link} from "react-router-dom";
import closee from "../../Utils/images/close.svg"
const BasketItem = ({itemData, dataPrice}) => {

    const locale = useSelector(state => state.langReducer.lang)
    const val = useSelector((state) => state.serviceReducers.valutes);
    const thisValute = val.key;
    const {updateItemQuantity, removeItem} = useCart();

    const [price, setPrice] = useState(+dataPrice);

    const dispatch = useDispatch();

    const handlerCountPlus = (id) => {
        updateItemQuantity(id, itemData.quantity + 1);
        setPrice(price + price);
    };

    const handlerCountMinus = (id) => {
        updateItemQuantity(id, itemData.quantity - 1);
        setPrice(price - price);
    };

    const close = (id) => {
        removeItem(id);
        dispatch(basketFilterDataGet(id));
    };

    const add = (dataMain) => {
    };


    return (
        <tr key={itemData.id} className={css.tr}>
            <>
                <Link to={"/Product/" + itemData.id}>
                    <td className={css.tdR}>
                        <img
                            className={css.thImg}
                            src={itemData.images?.substring(0, itemData.images.length)?.split(",")[0]}
                            alt="niImage"
                        />
                    </td>
                </Link>
                <td key={itemData.id} className={css.tdR}>
                    <h5>{itemData.productName}</h5>
                    {locale == "am" && <h5>{itemData.titleHy}</h5>}
                    {locale == "ru" && <h5>{itemData.titleRu}</h5>}
                    {locale == "en" && <h5>{itemData.titleEn}</h5>}
                </td>
                <td key={itemData.id} className={css.tdR}>

                    {thisValute == "price_amd" && <h6>{itemData.price_amd || itemData.priceAmd} ֏</h6>}
                    {thisValute == "price_eur" && <h6>{itemData.price_eur || itemData.priceEur} EUR</h6>}
                    {thisValute == "price_ru" && <h6>{itemData.price_rub || itemData.priceRub} RUB</h6>}
                    {thisValute == "price_usd" && <h6>{itemData.price_usd || itemData.priceUsd} USD</h6>}

                </td>
                <td key={itemData.id} className={css.tdR}>
                    <div className={css.PlusMinus}>
                        {itemData.quantity <= 1 ? (
                            <div className={css.spanClick}>
                                <AiOutlineMinus/>
                            </div>
                        ) : (
                            <div
                                className={css.spanClick}
                                onClick={() => handlerCountMinus(itemData.id)}
                            >
                                <AiOutlineMinus/>
                            </div>
                        )}

                        <div className={css.count}>{itemData.quantity}</div>
                        <div
                            className={css.spanClick}
                            onClick={() => handlerCountPlus(itemData.id)}
                        >
                            <AiOutlinePlus/>
                        </div>
                    </div>
                </td>
                <td key={itemData.id} className={css.tdR}>
                    <h6>{itemData.itemTotal} ֏</h6>
                </td>
                <td key={itemData.id}>
                    <div className={css.icons}>
                        <div className={css.iconsFlex}>
                            <img
                                className={css.iconsFlexImggg}
                                src={closee}
                                width="35"
                                height="35"
                                onClick={() => close(itemData.id)}
                            />
                        </div>
                    </div>
                </td>
            </>
        </tr>
    );
};

export default BasketItem;
