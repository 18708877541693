import {GET_MY_ORDERS} from "../Actions/myOrdersAction";


const initialState = {
    orders:null,
};

export const myOrders = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        default:
            return state;
    }
};
