import React, {useEffect, useState} from 'react';
import css from './view.module.css';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import ViewItem from "../ViewItem";
import {Tabs, Tab} from "react-bootstrap";
import {getMyOrdersThunk} from "../../Redux/Actions/myOrdersAction";
import del from "../../Utils/images/close.svg"
import axios from "axios";
import {JWT_TOKEN, url} from "../../config"

const ViewProfile = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState()
    const [listItems,setListItems] = useState([])
    const val = useSelector((state) => state.serviceReducers.valutes);
    const myOrders = useSelector(state => state?.myOrders.orders)
    const {t} = useTranslation()
    const token = localStorage.getItem(JWT_TOKEN)


    useEffect(() => {
        dispatch(getMyOrdersThunk())
    }, [])

    useEffect(() => {
        setData(myOrders)
    }, [myOrders])


    useEffect(() => {
        
    },[data])

// const handleDel =  (id) => {
//     axios.post(`${url}/api/v1/orders/delete-my-order`, {
//         id
//     },{
//         headers:{
//             Authorization:`Bearer ${token}`
//         }
//     })
//         .then(function (response) {
//             setData(data.filter(item => item.id !== id))

//         })
//         .catch(function (error) {
//             console.log(error);
//         });
// }

// delete items

const handleDel = async (id) => {
    try {
      const res = axios.post(`${url}/api/v1/orders/delete-my-order`,{
        id
      },{
        headers:{
           Authorization:`Bearer ${token}`
            } 
      })

      setData(data.filter(item => item.id !== id))
    }
    catch(err) {
      console.log('error',err);
    }
  }


    return (
        <div>
            <Container>
                <Row className="mt-5">
                    <Col lg={12} md={12} xs={12}>
                    </Col>
                    <Col lg={12} md={12} xs={12} className='hayko'>
                       
                           <h2 style={{textAlign:"center"}}>{t("myOrders")}</h2>
                                <Col lg={12} md={12} xs={12}>
                                    {data == undefined || data.length <= 0 ? <h3>{t("empty")}</h3> :
                                        <div className={css.table}>
                                            <table className={`${css.meekz} table table-dark`}>
                                                <thead>
                                                <tr className={css.tr}>
                                                    <th className={css.tdR}>{t("addd")}</th>
                                                    <th className={css.tdR}>{t("mail")}</th>
                                                    <th className={css.tdR}>{t("price")}</th>
                                                    <th className={css.tdR}>{t("phone")}</th>
                                                    <th className={css.tdR}>{t("date")}</th>
                                                    <th className={css.tdR}>{t("time")}</th>
                                                    <th className={css.tdR}>status</th>
                                                    <th className={css.tdR}>{t("action")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    data.length > 0 && data !== [] && data.map((item) => {
                                                        return (
                                                            <tr className={css.trBox} key={item.id}>
                                                                <td >{item.address}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.amount}</td>
                                                                <td>{item.phone}</td>
                                                                <td>{item.deleveryDate}</td>
                                                                <td>{item.deleveryTime}</td>
                                                                <td>{!item.status == "new" ? t("new") : null}
                                                                    {item.status == "true" ? t("true") : null}
                                                                    {item.status !== "true" && t("false")}
                                                                </td>
                                                                <td style={{cursor:"pointer"}}>
                                                                    <img src={del} alt="delete"
                                                                         onClick={() => 
                                                                            handleDel(item.id)
                                                                         }/>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </Col>
                           
                    </Col>

                </Row>
            </Container>
        </div>
    );
};

export default ViewProfile;