import React, {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import {Col, Container, Row, Alert, Button} from "react-bootstrap";
import css from "./productDetail.module.css";
import {AiOutlineMinus} from "react-icons/ai";
import {AiOutlinePlus} from "react-icons/ai";
import {AiOutlineHeart} from "react-icons/ai";
import {useCart} from "react-use-cart";
import {useDispatch, useSelector} from "react-redux";
import alert from "../../Utils/images/alert.svg";
import {getSingleProductThunk} from "../../Redux/Actions/aboutAction";
import logoInsta from "../../Utils/images/logoInsta.svg";
import fbLogo from "../../Utils/images/facebookLogo.svg";
import Swal from 'sweetalert2'
import {
    allAC,
    swishDataGet,
    swishRemoveDataGet,
} from "../../Redux/Actions/productActions";
import {useTranslation} from "react-i18next";
import {SwipeableDrawer} from "@mui/material";
import {FacebookShareButton, InstapaperShareButton} from "react-share";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';


const ProductDetail = () => {

    const [snackPack, setSnackPack] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState(undefined);
    const [swishItems,setSwishItems] = useState()
    const [swishItemId,setSwishItemId] = useState()
    const [active, setActive] = useState(false)

    const router = useParams();

    const prodId = router.id;

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({...snackPack[0]});
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }

        if(localStorage.getItem('swish')) {
            JSON.parse(localStorage.getItem('swish')).forEach(i => {
                if (i.id == prodId) {
                    setActive(true)
                }
            })
        }



    }, [snackPack, messageInfo, open]);

    const handleClick = (message) => () => {
        setSnackPack((prev) => [...prev, {message, key: new Date().getTime()}]);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(undefined);
    };


    const locale = useSelector((state) => state.langReducer.lang);
    const dispatch = useDispatch();
    const {addItem, items} = useCart();
    const products = useSelector((state) => state?.HomeReducers.all);
    const [count, setCount] = useState(1);
    const val = useSelector((state) => state.serviceReducers.valutes);
    const thisValute = val.key;

    useEffect(() => {
        dispatch(allAC());
    }, []);


    console.log(swishItems,'swishItemsswishItemsswishItemsswishItems');

    // const wishCount = () => {
    //     swishItems.map((item) => {
    //         console.log(item.id,'ididididiidididididii');
    //     })

    //     tooglee.map((i) => {
    //         console.log(i.id,('ioppppppppppppppppppppppppppppppppppp'));
    //     })

    //     setSwishItemId
    // }

    // wishCount()
    
    const [state, setState] = useState(false);
    const [img, setImages] = useState();
    let location = useLocation();
    const [show, setShow] = useState(false);
    const [textereaData, setTextereaData] = useState("");
    const tooglee = useSelector((state) => state.HomeReducers.swishData);
    // const router = useParams();
    const single = useSelector((state) => state.aboutReducer.single);
    // const prodId = router.id;
    const {t} = useTranslation();
    useEffect(() => {
        dispatch(getSingleProductThunk(prodId));
    }, []);

    const filterId =
        products &&
        products[0] &&
        products[0]?.filter((i) => {
            return prodId == i.id;
        });

    const filterPriceNumber = filterId?.map((item) => {
        return item.price;
    });


 
    let wishCart = localStorage.getItem('react-use-cart')
    let wishParse = JSON.parse(wishCart)
    console.log("🚀 ~ file: ProductDetail.js ~ line 103 ~ ProductDetail ~ wishParse", wishParse)

    // const wishFunc = () => {
    //     tooglee.forEach(() => {
            
    //     })
    // }

 
    const numberPrice = Number(filterPriceNumber);

    const [filterPrice, setFilterPrice] = useState();

    let imgMainn = filterId?.map((i) => {
        return i.productImage1;
    });

    useEffect(() => {
        setFilterPrice(numberPrice);
    }, [numberPrice]);

    const [imgMain, setImgMain] = useState();

    useEffect(() => {
        setImgMain(img && img.map((i) => i)[0]);
    }, [img]);

    const handelrImg = (log) => {
        setImgMain(log);
    };

    const handlerCountPlus = () => {
        setFilterPrice(filterPrice + numberPrice);
        setCount(count + 1);
    };

    let itemQuant = items.map((item) => {
        return Number(item.quantity);
    });

    const handlerCountMinus = () => {
        setFilterPrice(filterPrice - numberPrice);
        setCount(count - 1);
    };

    const [timeData, setTimeData] = useState("");
    const [timeDataError, setTimeDataError] = useState(false);
    const [calendarData, setCalendarData] = useState("");
    const [calendarDataError, setCalendarDataError] = useState("");

    let timeValue;
    const handlertime = (e) => {
        timeValue = e.target.value;
        setTimeData(timeValue);
    };

    let calendarValue;
    const handlerCalendar = (e) => {
        calendarValue = e.target.value;
        setCalendarData(calendarValue);
    };

    let textereaValue;
    const handlerTexterea = (e) => {
        textereaValue = e.target.value;
        setTextereaData(textereaValue);
    };

    useEffect(() => {
        // images
        setImages(
            single && single?.images?.substring(0, single.images.length)?.split(",")
        );
    }, [single]);

    const ToggleState = (item, id) => () => {

        setActive(!active)
        setState(!state);
        localStorage.removeItem('swish')
        !state ? dispatch(swishDataGet(item)) : dispatch(swishRemoveDataGet(id));
        active !== true ? 
        Swal.fire({
            imageUrl: 'https://www.svgrepo.com/show/13650/success.svg',
            title: t("saved"),
        }) : 
        Swal.fire({
            imageUrl: 'https://www.svgrepo.com/show/13650/success.svg',
            title: t("step"),
        }) 
    };

    const mainData = {
        id: single?.id,
        time: timeData,
        basket: single?.basket,
        count: count,
        calendar: calendarData,
        priceAmd: single?.price_amd,
        priceUsd: single?.price_usd,
        priceEur: single?.price_eur,
        priceRub: single?.price_rub,
        price: single?.price_amd,
        filterPrice: filterPrice,
        textereaDataMain: textereaData,
        titleEn: single?.titleEn,
        titleHy: single?.titleHy,
        titleRu: single?.titleRu,
        productImage1: single?.productImage1,
        calendarData: calendarValue,
    };

    const [fakeDate, setFakeDate] = useState();

    const addCart = (item, itemQuant = count) => {
        handleClick('Successfully');
        addItem({...item, price: item.price_amd}, (itemQuant = count));
    };

    const err = () => {
        setTimeDataError(true);
        setCalendarDataError(true);
    };

    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    },[] );

    // swishItems.map((i) => {
    //     return i.id
    // })
    console.log(textereaData)
    return (
        <div>
            <div>
                <Alert show={show} variant="success">
                    <div className={css.alert}>
                        <Alert.Heading>
                            <img
                                src={alert}
                                alt="Picture of the author"
                                width={30}
                                height={30}
                            />
                            Success: You have added to your shopping cart!
                        </Alert.Heading>
                        <div className="d-flex justify-content-end alertClose">
                            <img
                                src="/closeAlert.svg"
                                alt="Picture of the author"
                                width={30}
                                height={30}
                                onClick={() => setShow(false)}
                            />
                        </div>
                    </div>
                </Alert>
            </div>
            <Container>
                {/* <Snackbar
                    key={messageInfo ? messageInfo.key : undefined}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    TransitionProps={{onExited: handleExited}}
                    message={messageInfo ? messageInfo.message : undefined}
                    action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{p: 1}}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>

                    }
                /> */}
                <Row>
                    <Col lg={12} md={12} xs={12}>
                        <div className={css.textClock}>
                            <h3>{t("ordText")}</h3>
                        </div>
                    </Col>
                    <Col lg={6} md={12} xs={12} className="mt-5 mb-5">
                        {/* {filterId &&
                filterId.map((i) => {
                  return ( */}
                        <div>
                            <div className={css.detailImgMain}>
                                {imgMain == 0 ? (
                                    <img src={imgMain} alt="image"/>
                                ) : (
                                    <img src={imgMain} alt="image"/>
                                )}
                            </div>
                            <div className={css.detailImg}>
                                {img &&
                                    img.map((image, index) => {
                                        return (
                                            <img
                                                key={index}
                                                onClick={() => handelrImg(img[index])}
                                                src={img[index]}
                                                alt="image"
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        {/* );
                })} */}
                    </Col>
                    <Col lg={6} md={12} xs={12} className="mt-5 mb-5">
                        {/*{single &&*/}
                        {/*    single.map((item) => {*/}
                        {/*        return (*/}
                        <div>
                            <div className={css.detailName}>
                                {locale == "am" && <h2>{single?.titleHy}</h2>}
                                {locale == "ru" && <h2>{single?.titleRu}</h2>}
                                {locale == "en" && <h2>{single?.titleEn}</h2>}
                                <span>
                  {t("avel")}:
                                    {locale == "am" && <h3>{single?.availabilityHy}</h3>}
                                    {locale == "ru" && <h3>{single?.availabilityRu}</h3>}
                                    {locale == "en" && <h3>{single?.availabilityEn}</h3>}
                                    {/* {item.productInStock == true ? <p>In Stock</p> : "No"} */}
                </span>
                                <span>Model: {single?.model}</span>
                                {thisValute == "price_amd" && <h5 className={css.h5Tit}>AMD {single?.price_amd}</h5>}
                                {thisValute == "price_eur" && <h5 className={css.h5Tit}>EUR {single?.price_eur}</h5>}
                                {thisValute == "price_ru" && <h5 className={css.h5Tit}>RUB {single?.price_rub}</h5>}
                                {thisValute == "price_usd" && <h5 className={css.h5Tit}>$ {single?.price_usd}</h5>}
                            </div>

                            <div className={css.amount}>
                                <p>{t("amount")}:</p>
                                <div className={css.PlusMinus}>
                                    {count <= 1 ? (
                                        <div className={css.spanClick}>
                                            <AiOutlineMinus/>
                                        </div>
                                    ) : (
                                        <div className={css.spanClick} onClick={handlerCountMinus}>
                                            <AiOutlineMinus/>
                                        </div>
                                    )}

                                    <div className={css.count}>{count}</div>
                                    <div className={css.spanClick} onClick={handlerCountPlus}>
                                        <AiOutlinePlus/>
                                    </div>
                                </div>
                            </div>

                            {/*<div className={css.data}>*/}
                            {/*  <div className={css.calendarText}>*/}
                            {/*    <h3>{t("date")}:</h3>*/}
                            {/*  </div>*/}

                            {/*  <div className={css.dateInp}>*/}
                            {/*    <input type="date" onChange={handlerCalendar} />*/}
                            {/*    {timeDataError == true ? (*/}
                            {/*      <h5 className={css.err}>required</h5>*/}
                            {/*    ) : null}*/}
                            {/*  </div>*/}
                            {/*</div>*/}

                            {/*<div className={css.data}>*/}
                            {/*  <div className={css.calendarText}>*/}
                            {/*    <h3>{t("time")}:</h3>*/}
                            {/*  </div>*/}
                            {/*  <div className={css.dateInp}>*/}
                            {/*    <select name="Time" id="cars" onChange={handlertime}>*/}
                            {/*      <option value="09:00">09:00</option>*/}
                            {/*      <option value="10:00">10:00</option>*/}
                            {/*      <option value="11:00">11:00</option>*/}
                            {/*      <option value="12:00">12:00</option>*/}
                            {/*      <option value="13:00">13:00</option>*/}
                            {/*      <option value="14:00">14:00</option>*/}
                            {/*      <option value="15:00">15:00</option>*/}
                            {/*      <option value="16:00">16:00</option>*/}
                            {/*      <option value="17:00">17:00</option>*/}
                            {/*      <option value="18:00">18:00</option>*/}
                            {/*      <option value="19:00">19:00</option>*/}
                            {/*      <option value="20:00">20:00</option>*/}
                            {/*      <option value="21:00">21:00</option>*/}
                            {/*      <option value="22:00">22:00</option>*/}
                            {/*      <option value="23:00">23:00</option>*/}
                            {/*      <option value="24:00">24:00</option>*/}
                            {/*    </select>*/}
                            {/*    {timeDataError == true ? (*/}
                            {/*      <h5 className={css.err}>required</h5>*/}
                            {/*    ) : null}*/}
                            {/*  </div>*/}
                            {/*</div>*/}

                            <div className={css.texterea}>
                                <h3>{t("message")}</h3>
                                <input type="text" onChange={handlerTexterea}/>
                            </div>

                            <div className={css.addMain}>
                                <div className={css.add}>
                                    
                                    <button
                                        className={`${active ? css.setLove : css.love}`}
                                        onClick={ToggleState(single, single?.id)}>
                                        <AiOutlineHeart/>
                                    </button>



                                    <span onClick={handleClick('Successfully')}>
                                        <button
                                            className={css.clickk}
                                            onClick={() =>{
                                                addCart(
                                                    {
                                                        ...single,
                                                        timeData: timeData,
                                                        calendarData: calendarData,
                                                        images: single.images,
                                                        description: textereaData,
                                                    },
                                                    (itemQuant = count)
                                                )

                                                Swal.fire({
                                                    position: 'center',
                                                    icon: 'success',
                                                    title: t("saved"),
                                                    showConfirmButton: false,
                                                    timer: 1500
                                                })
                                            }}
                                        >
                                            {t("add")}
                                        </button>
                                    </span>

                                </div>

                                <div className={css.logo}>
                                    <h3>{t("Share")}:</h3>
                                    <span>
                    <span>

                    </span>
                    <span>
                       <FacebookShareButton
                           url={`https://flower.am/products`}
                           quote={"flower.am"}
                           hashtag={"#flower.am"}
                           description={"flower.am"}
                           className="Demo__some-network__share-button"
                       >
                                <img
                                    src={fbLogo}
                                    alt="Picture of the author"
                                    width={25}
                                    height={25}
                                />
                        </FacebookShareButton>

                    </span>
                                        {"   "}
                  </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={css.diteilBg}></div>
        </div>
    );
};

export default ProductDetail;
