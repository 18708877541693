import axios from "axios";
import {JWT_TOKEN, url} from '../../config'

export const GET_MY_ORDERS = "GET_MY_ORDERS"
const token = localStorage.getItem(JWT_TOKEN)

export const getMyOrdersThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/orders/my-orders`,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        });
        dispatch({
            type: GET_MY_ORDERS,
            payload: response.data,
        });
    };
}

