//static page
export const HOME_PAGE = "/";
export const ABOUT_PAGE = "/about-us";
export const BASKET_PAGE = "/basket";
export const CONTACT_PAGE = "/contact-us";
export const LOGIN_PAGE = "/sign-in";
export const REGISTER_PAGE = "/sign-up";
export const BILLING_PAGE = "/billing";
export const PRODUCT_PAGE = "/products";
export const PROFILE_PAGE = "/profile";
export const SERVICES_PAGE = "/services";
export const WISHLIST_PAGE = "/wish-list";
export const SUCCSESS_PAGE = "/success";
export const FAIL_PAGE = "/fail";
export const CONTACT_US = "/contact";
export const PROFILE = "/profile";
export const ERROR_PAGE = "/404";
export const DELEVERY_PAGE = "/delevery"
export const PAYMENT_PAGE = "/payment"
export const IDRAM_PAGE = "/idram/:id";
// detail pages
export const FLOWER_DETAIL_PAGE = "/product" + "/:id";
