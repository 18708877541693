import React, {useEffect} from 'react';
import {Container} from "react-bootstrap"
import {useTranslation} from "react-i18next";

const Payment = () => {

    const {t} = useTranslation()
    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    },[] );
    return (<div>
        <Container className="mt-3">
            <h1>{t("tsar")}</h1>

            <pre>{t("hraparak")}</pre>
            <pre>{t("tarm")}</pre>
            <p>{t("text1")}</p>
            <br/>
            <p>{t("text2")}</p>

        </Container>
    </div>)
        ;
};

export default Payment;