import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import css from "./home.module.css";
import Slider from "react-slick";
import HomeTwo from "./HomeTwo";
import HomeFloral from "./HomeFloral";
import HomeTouch from "./HomeTouch";
import HomeMap from "./HomeMap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getShortAbotThunk,
  homeBanerAc,
} from "../../Redux/Actions/banersAction";
import Bmbul from '../../Utils/images/bmbul.svg'
import onlineFloristText from "../../Utils/images/onlineFloristText.svg";
import homeSlider1_2 from "../../Utils/images/homeSlider1_2.png";
// import homeSlider1_3 from "../../Utils/images/homeSlider1_3.svg";
// import homeSlider1_4 from "../../Utils/images/homeSlider1_4.svg";
// import homeSlider1_5 from "../../Utils/images/homeSlider1_5.svg";
// import homeSlider1 from "../../Utils/images/homeSlider1.svg";
import homeSliderTextTwo from "../../Utils/images/homeSliderTextTwo.svg";
import { useTranslation } from "react-i18next";
import pic1 from "../../Utils/images/slider/Pic1.png"
import pic2 from "../../Utils/images/slider/Frame 74.png"
import pic3 from "../../Utils/images/slider/Group 2436.png"
import pic4 from "../../Utils/images/slider/Rectangle 14.png"
import pic5 from "../../Utils/images/slider/Rectangle 13.png"
import pic6 from "../../Utils/images/slider/Frame 73.png"
import pic7 from "../../Utils/images/slider/Pic2.png"
import pic8 from "../../Utils/images/slider/Pic3.png"
import pic9 from "../../Utils/images/slider/Rectangle 14.5.png" 
import pic10 from "../../Utils/images/slider/Rectangle 13.5.png" 
const Home = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const [bgStyle, setBgStyle] = useState({});
  const banner = useSelector((state) => state.banerReducer.homeBaner);
  const locale = useSelector((state) => state.langReducer.lang);
  const shortAbout = useSelector((state) => state.banerReducer.shortAbout);
  useEffect(() => {
    dispatch(homeBanerAc());
    dispatch(getShortAbotThunk());
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // console.log = console.warn = console.error = () => {};
    console.clear()
  },[] );

  return (
    <div>
      {banner ? (
        <div
          className={css.homeBg}
          style={{ 
            backgroundImage: `url(${banner.image})` 
          }}
          
        >
          <Container>
            <Row className="justify-content-md-center">
              <Col lg={12} md={12} xs={12}>
                <div className={css.homeBgText}>
                  <div className={css.homeTextTwo}>
                    {locale == "am" && <h1>{banner.titleHy}</h1>}
                    {locale == "en" && <h1>{banner.titleEn}</h1>}
                    {locale == "ru" && <h1>{banner.titleRu}</h1>}

                    {locale == "am" && <h1>{banner.subTitleHy}</h1>}
                    {locale == "en" && <h1>{banner.subTitleEn}</h1>}
                    {locale == "ru" && <h1>{banner.subTitleRu}</h1>}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}

      <Container>
        <Row>
          <Col lg={12} md={12} xs={12} className="mt-5">
            <Slider {...settings}>
              <div>
                <div>
                  <div className={css.homeSlider1}>
                    <div className={css.homeSliderTExt1}>
                      <img
                        src={onlineFloristText}
                        alt="Picture of the author"
                        width={200}
                        height={80}
                      />
                      <h3>Express your interest by donating flowers</h3>
                    </div>
                    <div className={css.img1}>
                      <img
                        src={pic2}
                        alt="Picture of the author"
                        width={280}
                        height={200}
                        className={css.caxkaman}
                      />
                      <img
                        src={pic1}
                        alt="Picture of the author"
                        width={380}
                        height={380}
                      />
                    </div>

                    <div className={css.homeSliderDiv2}>
                      <Col lg={6} md={12} xs={12}>
                        <div className={css.homeSlider1Flex}>
                          <img
                            src={pic3}
                            alt="Picture of the author"
                            width={380}
                            height={380}
                          />
                          <div className={css.homeSlider1Direction}>
                            <img
                              src={pic4}
                              alt="Picture of the author"
                              width={200}
                              height={200}
                            />
                            <img
                              src={pic5}
                              alt="Picture of the author"
                              width={200}
                              height={200}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <div className={css.homeSliderDiv2Text}>
                          <img
                            src={homeSliderTextTwo}
                            alt="Picture of the author"
                            width={150}
                            height={100}
                          />
                          <div className={css.homeSliderText2Direction}>
                            <h3>New and popular</h3>
                            <h3>flowers on the page</h3>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className={css.homeSlider1}>
                    <div className={css.homeSliderTExt1}>
                      <img
                        src={onlineFloristText}
                        alt="Picture of the author"
                        width={200}
                        height={80}
                      />
                      <h3>Express your interest by donating flowers</h3>
                    </div>
                    <div className={css.img1}>
                      <img
                        src={homeSlider1_2}
                        alt="Picture of the author"
                        width={280}
                        height={200}
                        className={css.caxkaman}
                      />
                      <img
                        src={pic6}
                        alt="Picture of the author"
                        width={380}
                        height={380}
                      />
                    </div>

                    <div className={css.homeSliderDiv2}>
                      <Col lg={6} md={12} xs={12}>
                        <div className={css.homeSlider1Flex}>
                          <img
                            src={pic8}
                            alt="Picture of the author"
                            width={380}
                            height={380}
                          />
                          <div className={css.homeSlider1Direction}>
                            <img
                              src={pic9}
                              alt="Picture of the author"
                              width={200}
                              height={200}
                            />
                            <img
                              src={pic10}
                              alt="Picture of the author"
                              width={200}
                              height={200}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <div className={css.homeSliderDiv2Text}>
                          <img
                            src={homeSliderTextTwo}
                            alt="Picture of the author"
                            width={150}
                            height={100}
                          />
                          <div className={css.homeSliderText2Direction}>
                            <h3>New and popular</h3>
                            <h3>flowers on the page</h3>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div>
                <div>
                  <div className={css.homeSlider1}>
                    <div className={css.homeSliderTExt1}>
                      <img
                        src={onlineFloristText}
                        alt="Picture of the author"
                        width={200}
                        height={80}
                      />
                      <h3>Express your interest by donating flowers</h3>
                    </div>
                    <div className={css.img1}>
                      <img
                        src={homeSlider1_2}
                        alt="Picture of the author"
                        width={280}
                        height={200}
                        className={css.caxkaman}
                      />
                      <img
                        src={homeSlider1}
                        alt="Picture of the author"
                        width={380}
                        height={380}
                      />
                    </div>

                    <div className={css.homeSliderDiv2}>
                      <Col lg={6} md={12} xs={12}>
                        <div className={css.homeSlider1Flex}>
                          <img
                            src={homeSlider1_3}
                            alt="Picture of the author"
                            width={380}
                            height={380}
                          />
                          <div className={css.homeSlider1Direction}>
                            <img
                              src={homeSlider1_4}
                              alt="Picture of the author"
                              width={200}
                              height={200}
                            />
                            <img
                              src={homeSlider1_5}
                              alt="Picture of the author"
                              width={200}
                              height={200}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <div className={css.homeSliderDiv2Text}>
                          <img
                            src={homeSliderTextTwo}
                            alt="Picture of the author"
                            width={150}
                            height={100}
                          />
                          <div className={css.homeSliderText2Direction}>
                            <h3>New and popular</h3>
                            <h3>flowers on the page</h3>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div> */}
            </Slider>
          </Col>
          <Col lg={12} md={12} xs={12} className="mt-5">
            <div className={css.homeAbout}>
                 {locale == "am" && <h1>{shortAbout && shortAbout.titleHy}</h1>}
                 {locale == "ru" && <h1>{shortAbout && shortAbout.titleRu}</h1>}
                {locale == "en" && <h1>{shortAbout && shortAbout.titleEn}</h1>}

                    {locale == "am" && <p>{shortAbout && shortAbout.short_about_hy}</p>}
                {locale == "ru" && <p>{shortAbout && shortAbout.short_about_ru}</p>}
                {locale == "en" && <p>{shortAbout && shortAbout.short_about_en}</p>}
              <Link to="/about-us">{t("more")}</Link>
              <div className={css.homeAboutImg}>
                <img
                  src={Bmbul}
                  alt="Picture of the author"
                  width={150}
                  height={100}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <HomeTwo />
      </div>
      <Container>
        <Row className="justify-content-md-center">
          <HomeFloral />
        </Row>
      </Container>

      <div className={css.homeTouch}>
        <Container>
          <Row>
            <HomeTouch />
          </Row>
        </Container>
      </div>
      <div className={css.homeMap}>
        <HomeMap />
      </div>
    </div>
  );
};

export default Home;