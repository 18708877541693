import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import Header from "./Containers/Header/Header";
import Footer from "./Containers/Footer/Footer";
import Pages from "../src/Routing/Pages";
import {BsTelephone} from 'react-icons/bs'
import {useEffect} from "react";

function App() {
  const { t } = useTranslation();
    useEffect(() => {
        console.clear()
    },[] );
  return (
    <div>
      <Header />
      <Pages />
        <div className='telDiv'>
            <div>
                <a href="tel:+37494530374"><BsTelephone/></a>
            </div>
        </div>
      <Footer />
    </div>
  );
}

export default App;
