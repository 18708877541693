import React, {useEffect, useState} from "react";
import css from "./footer.module.css";
import {Col, Container, Row, Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";
import arca from "../../Utils/images/arca.svg";
import idram from "../../Utils/images/idramStyle.png";
import webMony from "../../Utils/images/webMony.svg";
import mony from "../../Utils/images/mony.svg";
import visa from "../../Utils/images/visa.svg";
import masterCard from "../../Utils/images/masterCard.svg";
import verified from "../../Utils/images/verified.svg";
import amerian from "../../Utils/images/amerian.svg";
import {useTranslation} from "react-i18next";
import {JWT_TOKEN} from "../../config";

const Footer = () => {
    const {t} = useTranslation()
    const [auth, setAuth] = useState(false)

    useEffect(() => {
        const a = localStorage.getItem(JWT_TOKEN) !== null && setAuth(true)
    }, [])
    return (
        <div>
            <div className={css.footherBorder}/>

            <Container>
                <Row>
                    <Col lg={3} md={4} xs={12}>
                        <div className={css.footLink}>
                            <h3>{t("info")}</h3>
                            <Link to="/products">{t("product")}</Link>
                            <Link to="/services">{t("services")}</Link>
                            <Link to="/about-us">{t("about")}</Link>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={12}>
                        <div className={css.footLink}>
                            <h3>{t("why")}</h3>
                            <Link to="/delevery">{t("delev")}</Link>
                            <Link to="/payment">{t("pays")}</Link>
                        </div>
                    </Col>
                    <Col lg={2} md={4} xs={12}>
                        <div className={css.footLink}>
                            <h3>{t("my")}</h3>
                            {!auth && (
                                <>
                                    <Link to="/sign-up">{t("signUp")}</Link>
                                    <Link to="/sign-in">{t("signIn")}</Link>
                                </>
                            )}

                            <Link to="/Wish-List">{t("wishList")}</Link>
                        </div>
                    </Col>
                    <Col lg={4} md={12} xs={12}>
                        <div className={css.footText}>
                            <div className={css.footText2}>
                                <h3>{t("contacts")}</h3>
                                <p>{t("addres")}</p>
                                <p>{t("phones")}: +374 10 530374; +374 94 530374</p>
                                <p>{t("mail")}: order@flower.am</p>
                                <div className={css.cardImages} style={{
			alignItems:"center"
}}>
                                    <img
                                        src={arca}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                    <img
                                        src={idram}
                                        alt="Picture of the author"
                                        width={80}
                                        height={60}
                                        style={{width:"5rem",height:"2rem"}}
			
                                    />
                                    <img
                                        src={webMony}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                    <img
                                        src={mony}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                    <img
                                        src={visa}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                    <img
                                        src={masterCard}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                    <img
                                        src={verified}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                    <img
                                        src={amerian}
                                        alt="Picture of the author"
                                        width={80}
                                        height={80}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={css.footMedia}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t("info")}</Accordion.Header>
                                    <Accordion.Body className={css.mediaFotterLinks}><Link
                                        to="/products">{t("product")}</Link></Accordion.Body>
                                    <Accordion.Body className={css.mediaFotterLinks}><Link
                                        to="/services">{t("services")}</Link></Accordion.Body>
                                    <Accordion.Body className={css.mediaFotterLinks}><Link
                                        to="/about-us">{t("about")}</Link></Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>{t("why")}</Accordion.Header>
                                    <Accordion.Body className={css.mediaFotterLinks}><Link
                                        className={css.mediaFotterLinks}
                                        to="/delevery">{t("delev")}</Link></Accordion.Body>
                                    <Accordion.Body className={css.mediaFotterLinks}><Link
                                        className={css.mediaFotterLinks}
                                        to="/payment">{t("pays")}</Link></Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t("my")}</Accordion.Header>
                                    {!auth && (<>
                                        <Accordion.Body className={css.mediaFotterLinks}><Link
                                            to="/sign-up">{t("signUp")}</Link></Accordion.Body>
                                        <Accordion.Body className={css.mediaFotterLinks}><Link
                                            to="/sign-in">{t("signIn")}</Link></Accordion.Body></>)}
                                    <Accordion.Body className={css.mediaFotterLinks}><Link
                                        to="/Wish-List">{t("wishList")}</Link></Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>{t("contacts")}</Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t("addres")}</p>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <p>{t("phones")}: +374 10 530374; +374 94 530374</p>
                                    </Accordion.Body>
                                    <Accordion.Body>
                                        <p>{t("mail")}: order@flower.am</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Payments</Accordion.Header>
                                    <Accordion.Body>
                                        <div className={css.cardImages}>
                                            <img
                                                src={arca}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={idram}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={webMony}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={mony}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={visa}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={masterCard}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={verified}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                            <img
                                                src={amerian}
                                                alt="Picture of the author"
                                                width={80}
                                                height={80}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={css.footDownText}>
                <p>
                    {" "}
                    2022, Copyright By
                    <a href="http://armcoding.com/" target="_blank">
                        ArmCoding
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
