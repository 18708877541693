import axios from "axios";
import {url} from '../../config'

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_HOME_BANNER = 'GET_HOME_BANNER'
export const GET_HOME_INFO ="GET_HOME_INFO"
export const HOME_ITEMS = "HOME_ITEMS" 
export const GET_ABOUT_INFO = "GET_ABOUT_INFO"
export const GET_ABOUT_BANNER = "About_Banner"
export const GET_SHORT_ABOUT = "GET_SHORT_ABOUT" 


export const getBanerCategoriesThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/category/`)
        dispatch({
            type: GET_CATEGORIES,
            payload: response.data,
        });

    };
}

export const getShortAbotThunk = () =>{
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/settings/about/short`)
        dispatch({
            type:GET_SHORT_ABOUT,
            payload:response.data[0]
        })
    }
}

export const getAboutBannerThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/settings/banner/about/get`)
        dispatch({
            type: GET_ABOUT_BANNER,
            payload: response.data[0],
        });

    };
}

export const getContactUsInfoThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/contact/`)
        dispatch({
            type: GET_ABOUT_INFO,
            payload: response.data[0],
        });

    };
}


export const homeBanerAc = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/settings/banner/home/get/`)
        dispatch({
            type: GET_HOME_BANNER,
            payload: response.data,
        });

    };
}

export const homeInfoAc = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/information/`)
        dispatch({
            type: GET_HOME_INFO,
            payload: response.data,
        });

    };
}

export const homeItemsAc = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/product/all`)
        dispatch({
            type: HOME_ITEMS,
            payload: response.data.slice(0,4),
        });

    };
}



