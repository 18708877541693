import React, {useEffect} from 'react';
import axios from "axios";
import {url} from '../../config'
import {useTranslation} from "react-i18next";

const Success = () => {


    useEffect(()=>{
       const {data} = axios.get(`${url}/api/v1/ord/payment`)
    },[])

    const {t} = useTranslation()

    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    },[] );
    return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto mt-5">
                        <div className="payment">
                            <div className="payment_header">
                                <div className="check"><i style={{color: "green"}} className="fa fa-check" aria-hidden={true}></i></div>
                            </div>
                            <div className="content">
                                <h1>{t("success")} !</h1>
                                <br/>
                                <a href="/">{t("backHome")} </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    );
};



export default Success;