import {
    ABOUT_PAGE,
    BASKET_PAGE,
    BILLING_PAGE, CONTACT_US, DELEVERY_PAGE,
    FAIL_PAGE,
    FLOWER_DETAIL_PAGE,
    HOME_PAGE, IDRAM_PAGE,
    LOGIN_PAGE, PAYMENT_PAGE,
    PRODUCT_PAGE, PROFILE,
    REGISTER_PAGE,
    SERVICES_PAGE,
    SUCCSESS_PAGE,
    WISHLIST_PAGE,
} from "./urls";
import AboutUs from "../Components/AboutUs/AboutUs";
import Home from "../Components/Home/Home";
import Product from "../Components/Product/Product";
import Service from "../Components/Services/Services";
import Basket from "../Components/Basket/Basket";
import WishList from "../Components/WishList/WishList";
import SignUp from "../Components/SignUp/SignUp";
import SignIn from "../Components/SignIn/SignIn";
import Success from "../Components/Succses/Success";
import Fail from "../Components/Fail/Fail";
import ProductDetail from "../Components/ProductDetail/ProductDetail";
import Billing from "../Components/Billing/Billing";
import ContactComponents from "../Components/ContactUs";
import ViewProfile from "../Components/ViewProfile";
import Delevery from "../Components/Delevery/Delevery";
import Payment from "../Components/Payment/Payment";
import Idram from "../Components/Idram/Idram";


export const publickPages = [
    {id: 1, path: HOME_PAGE, name: "Home", Component: Home},
    {id: 2, path: SERVICES_PAGE, name: "Service", Component: Service},
    {id: 3, path: PRODUCT_PAGE, name: "Product", Component: Product},
    {id: 4, path: ABOUT_PAGE, name: "AboutUs", Component: AboutUs},
    {id: 5, path: CONTACT_US, name: "ContactUs", Component: ContactComponents},
];

export const sidePages = [
    {id: 1, path: BASKET_PAGE, name: "Basket", Component: Basket},
    {id: 2, path: WISHLIST_PAGE, name: "Wish List", Component: WishList},
    {id: 2, path: LOGIN_PAGE, name: "Sign In", Component: SignIn},
    {id: 2, path: REGISTER_PAGE, name: "Sign Up", Component: SignUp},
    {id: 2, path: BILLING_PAGE, name: "Billing", Component: Billing},
];

export const paymentPages = [
    {id: 1, path: SUCCSESS_PAGE, name: "Succsess", Component: Success},
    {id: 2, path: FAIL_PAGE, name: "Fail", Component: Fail},
];

export const termPages = [
    {id: 1, path: DELEVERY_PAGE, name: "Delevery", Component: Delevery},
    {id: 2, path: PAYMENT_PAGE, name: "Payment", Component: Payment}
]
export const detailsPages = [
    {id: 1, path: FLOWER_DETAIL_PAGE, name: "Item", Component: ProductDetail},
];


export const profileViews = [
    {id: 1, path: PROFILE, name: "Profile", Component: ViewProfile},
    {id: 2, path: IDRAM_PAGE, name: "Idram", Component: Idram},

]