import React, { useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import css from "./header.module.css";
import { RiShoppingCartLine } from "react-icons/ri";
import { AiOutlineHeart } from "react-icons/ai";
import { useCart } from "react-use-cart";
import { useDispatch, useSelector } from "react-redux";
import { filterDataGet } from "../../Redux/Actions/productActions";
import { useNavigate } from "react-router-dom";
import { changeValueteAC } from "../../Redux/Actions/productAAction";
import { JWT_TOKEN, myUrl } from "../../config";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../Utils/images/logo2.png";
import {
  ABOUT_PAGE,
  CONTACT_US,
  HOME_PAGE,
  PRODUCT_PAGE,
  PROFILE,
  REGISTER_PAGE,
  SERVICES_PAGE
} from "../../Routing/urls";
import i18next from "i18next";
import cookies from "js-cookie";
import {chageLanguageAC} from "../../Redux/Actions/langAction" 
import {useTranslation} from "react-i18next";
import User from '../../Utils/images/user.svg'

const Header = () => {
  const [heart,setHeart] = useState()
  const router = useNavigate();
  let location = useLocation();
  const { totalItems } = useCart();
  const {t} = useTranslation()
  const [auth, setAuth] = useState(false);
  const [closeNav,setCloseNav] = useState(false)

  let xx;
  // useEffect(() => {
  //   xx = JSON.parse(localStorage?.getItem("swish")) &&  JSON.parse(localStorage.getItem("swish"));
  // }, []);
  const lengthSwish = useSelector((state) => state.HomeReducers.swishData);
  const [argument, setarrangementM] = useState();
  const [local, setLocal] = useState([]);
  const [active, setActive] = useState(true);
  const [show, setShow] = useState(false);
  const [defaultValute, setDefaultValue] = useState({
    id: 1,
    name: "AMD",
    key: "price_amd",
  });
  const val = useSelector((state) => state.serviceReducers.valutes);
  const language = useSelector(state => state.langReducer.lang)
  const dispatch = useDispatch();

  const valutes = [
    { id: 1, name: "AMD", key: "price_amd" },
    { id: 2, name: "USD", key: "price_usd" },
    { id: 3, name: "RUB", key: "price_ru" },
    { id: 4, name: "EUR", key: "price_eur" },
  ];

  const languages = [
    { id: 1, lang: "en" },
    { id: 2, lang: "am" },
    { id: 3, lang: "ru" },
  ];


  const urls = [
    { id: 1, path: HOME_PAGE, name: t("home")},
    { id: 2, path: SERVICES_PAGE, name: t("services")},
    { id: 3, path: PRODUCT_PAGE, name: t("product")},
    { id: 4, path: ABOUT_PAGE, name: t("about")},
    { id: 5, path: CONTACT_US, name: t("Contact")},
  ]

  const profile = [
    { id: 1, path: PROFILE, name: t("Profile")},
  ]

  const currentLang = cookies.get("i18next");
  const [activeLang, setActiveLang] = useState(currentLang);
  const selectlanguages = (lang) => {
    i18next.changeLanguage(lang);
    setActiveLang(lang);
    dispatch(chageLanguageAC(lang))
  };

  useEffect(() => {
    localStorage?.setItem("valute", JSON.stringify(defaultValute));
    dispatch(changeValueteAC(defaultValute));
  }, [defaultValute]);

  const activeAdd = () => {
    setActive(true);
  };

  // let a = localStorage?.getItem('swish')
  useEffect(() => {
    localStorage?.getItem(JWT_TOKEN) !== null && setAuth(true);
    // a = a && a[0] && JSON.parse(a)
    setHeart(a)
  }, []);

  // useEffect(() => {
  //   a = JSON.parse(a)
  //   setHeart(a)
  // }, [a]);

  // var swishLocal;
  // if (typeof window !== "undefined") {
  //   swishLocal = localStorage?.getItem("swish");
  // } else {
  // }
  // useEffect(() => {
  //   setLocal(
  //     swishLocal && swishLocal[0] && swishLocal.length > 0 ? JSON.parse(swishLocal) : null
  //   );
  // }, [lengthSwish]);


  let a = localStorage.getItem('swish')
  let b = JSON.parse(a)

  // alert(b.length)

  let dataMain;
  const drop1 = (name) => {
    setarrangementM((dataMain = { arrangementMain: name }));
    dispatch(filterDataGet(dataMain));
  };

  const logOut = () => {
    localStorage.removeItem(JWT_TOKEN)
    window.location.href = myUrl
  }

  const closeMenu = () => {
    if (show == true) {
      setShow(false);
    }
  };


  return (
    <div className={css.headerBg}>
      <Container fluid>
        <div className={css.headerMain}>
          <Navbar
            collapseOnSelect={false}
            expand="lg"
            expanded={closeNav}  
           >
            <Navbar.Brand className={css.HeaderLogo}>
              <Link to="/">
                <img
                    src={logo}
                    alt="Picture of the author"
                    width={100}
                    height={50}
                />
              </Link>

            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={()=>{setCloseNav(closeNav ? false : "closeNav")}} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto my-2 my-lg-0 align-items-center  headerNav" >
                  {urls.map((i) => {
                    return (
                        <Link
                            onClick={()=>setCloseNav(false)}
                            className={css.lika}
                            to={i.path}
                            key={i.id}>
                          {i.name}
                          <div
                              className={
                                location.pathname == i.path ? "active" : "lika"
                              }
                          />
                        </Link>
                    );
                  })}
              </Nav>

              <div className={css.cont1}>
                <div className={css.headerImages}>
                  <div className={css.basketCount}>
                    {totalItems > 0 && totalItems && (
                      <div>
                        <p>{totalItems}</p>
                      </div>
                    )}
                    <Link  onClick={()=>setCloseNav(false)} to="/basket">
                      <span>
                        <RiShoppingCartLine />
                      </span>
                    </Link>
                  </div>
                  <div className={css.basketCount}>
                    {xx ? (
                      <div>
                        <p>{xx.length}</p>
                      </div>
                    ) : null}
                    <Link
                     onClick={()=>setCloseNav(false)}
                     to="/Wish-List">
                      <span>
                        <AiOutlineHeart />
                      {!b?.length == 0  &&  <small className={css.heart_count_position}></small>} 
                      </span>
                    </Link>
                  </div>
                </div>

                {/*<Dropdown>*/}
                {/*  <Dropdown.Toggle className={css.btnAm}>*/}
                {/*    {defaultValute.name}*/}
                {/*  </Dropdown.Toggle>*/}

                {/*  <Dropdown.Menu className={css.dropAm}>*/}
                {/*    {valutes*/}
                {/*      .filter((i) => i.key !== defaultValute.key)*/}
                {/*      .map((v) => {*/}
                {/*        return (*/}
                {/*          <Dropdown.Item onClick={() => setDefaultValue(v)}>*/}
                {/*            {v.name}*/}
                {/*          </Dropdown.Item>*/}
                {/*        );*/}
                {/*      })}*/}
                {/*  </Dropdown.Menu>*/}
                {/*</Dropdown>*/}
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className={css.btnAm}>
                    {activeLang.toUpperCase()}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={css.dropAm}>
                    {/* {langs
                      .filter((i) => i.key !== activeLang)
                      .map((lang) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setActiveLang(lang.key)}
                          >
                            {lang.name}
                          </Dropdown.Item>
                        );
                      })} */}
                    {languages.filter((i) => i.lang !== activeLang).map(({ id, lang }) => {
                      return (
                        <Dropdown.Item
                          key={id}
                          onClick={() => {
                            selectlanguages(lang, id);
                            setActiveLang(lang);
                          }}
                          // className={lang === activeLang ? 'language active' : 'language'}
                        >
                          {lang.toUpperCase()}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>


                {auth ? <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" className={css.btnAm}>
                        <div  className={css.dropUser}>
                          <img src={User} width="25" height="25" />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={css.dropAm}>
                        {profile.map((i) => {
                          return (
                              <Link  onClick={()=>setCloseNav(false)} className={css.lika} to={i.path} key={i.id}>
                                {i.name}
                                <div
                                    className={
                                      location.pathname == i.path ? "active" : "lika"
                                    }
                                />
                              </Link>
                          );
                        })}
                        <button onClick={logOut}>{t('logout')}</button>
                      </Dropdown.Menu>
                    </Dropdown> :
                  <Link  onClick={()=>setCloseNav(false)} to={REGISTER_PAGE}>
                    <button className={css.loginBtn}>{t('signIn')}</button>
                  </Link>
                }
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Container>
    </div>
  );
};

export default Header;
