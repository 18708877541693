import { GET_SERVICES_PAG } from "../Actions/aboutAction";
import { CHANGE_VALUE } from "../Actions/productAAction";

export const SERVICE_DATA = "SERVICE_DATA";

const initialState = {
  serviceData: [],
  valutes: [
    { id: 1, name: "AMD", key: "price_amd" },
    // {id:2,name:"USD",key:"price_amd"},
    // {id:3,name:"RUB",key:"price_ru"},
    // {id:4,name:"EUR",key:"price_eur"},
  ],
  all:[]
};

export const serviceReducers = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_DATA:
      return { ...state, serviceData: action.payload };

    case CHANGE_VALUE:
        return {...state,valutes:action.payload}
    case GET_SERVICES_PAG:
      return {
        ...state,
        all:action.payload
      }
    default:
      return state;
  }
};
