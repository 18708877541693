import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

const Fail = () => {
    const {t} = useTranslation()

    useEffect(() => {
        // console.log = console.warn = console.error = () => {};
        console.clear()
    },[] );
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 mx-auto mt-5">
                    <div className="payment">
                        <div className="payment_header_fail">
                            <div className="check"><i style={{color:"maroon"}} className="fa-solid fa-square-xmark" aria-hidden={true}></i></div>
                        </div>
                        <div className="content">
                            <h1>{t("failPay")} !!!</h1>
                            <br/>
                            <a className="fail" href="/">{t("backHome")}</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fail;