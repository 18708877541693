import React, {useEffect, useState} from "react";
import css from "./homeItem.module.css";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Col} from "react-bootstrap";
import {
    basketDataGet,
    swishDataGet,
    swishRemoveDataGet,
} from "../../../Redux/Actions/productActions";
import {useCart} from "react-use-cart";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Swal from 'sweetalert2'
import {ReactComponent as RedBasket} from "../../../Utils/images/basketRed.svg";
import {ReactComponent as Love} from "../../../Utils/images/love.svg";
import {ReactComponent as Basket} from "../../../Utils/images/basket.svg";
import {useTranslation} from "react-i18next";


const HomeItem = ({item}) => {
    const [show, setShow] = useState(true);
    const locale = useSelector(state => state.langReducer.lang);
    const [snackPack, setSnackPack] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState(undefined);
    const val = useSelector((state) => state.serviceReducers.valutes);
    const thisValute = val.key;
    const tooglee = useSelector((state) => state.HomeReducers.swishData);
    const [choosen, setChoosen] = useState(false)
    const [like, setLike] = useState(false)
    const {t} = useTranslation()
    const [swishItems, setSwishItems] = useState([])
    const [inBasket, setInBasket] = useState(false)
    const [basketItems, setBasketItems] = useState([])
    // useEffect(() => {
    //     if (snackPack.length && !messageInfo) {
    //         // Set a new snack when we don't have an active one
    //         setMessageInfo({...snackPack[0]});
    //         setSnackPack((prev) => prev.slice(1));
    //         setOpen(true);
    //     } else if (snackPack.length && messageInfo && open) {
    //         // Close an active snack when a new one is added
    //         setOpen(false);
    //     }
    // }, [snackPack, messageInfo]);

    const handleClick = (message) => () => {
        setSnackPack((prev) => [...prev, {message, key: new Date().getTime()}]);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    const {addItem} = useCart();

    const [state, setState] = useState(false);

    useEffect(() => {
        setSwishItems(JSON.parse(localStorage.getItem("swish")))
        setBasketItems(JSON.parse(localStorage.getItem("react-use-cart")).items)
    }, [inBasket, like])

    const dispatch = useDispatch();
    const ToggleState = (item, id) => () => {
        setState(!state);
        setLike(!like)
        !like ? dispatch(swishDataGet(item)) : dispatch(swishRemoveDataGet(id));
        !like ? Swal.fire({
            position: 'center',
            icon: 'success',
            title: t("saved"),
            showConfirmButton: false,
            timer: 1500
        }) : Swal.fire({
            position: 'center',
            icon: 'success',
            title: t("canceled"),
            showConfirmButton: false,
            timer: 1500
        })
    };

    const setBasket = (item) => {
        addItem({
            ...item,
            price: item.price_amd,
        });
        dispatch(basketDataGet(item));
    };

    return (
        <Col key={item.id} lg={6} md={6} xs={12}>
            <div className={css.mainProduct}>
                <Link to={"/Product/" + item.id}>
                    <div className={css.productDiv}>
                        <img
                            src={item.images.substr(0, item.images.length).split(",")[0]}
                            alt="image"
                            height={330}
                            width={330}
                        />
                        <div className={css.www}>
                            <h2>WWW.FLOWER.AM</h2>
                        </div>
                        <div className={css.productPrice}>
                            {thisValute == "price_amd" && <p>{item.price_amd} ֏</p>}
                            {thisValute == "price_ru" && <p>{item.price_rub} RUB</p>}
                            {thisValute == "price_usd" && <p>{item.price_usd} USD</p>}
                            {thisValute == "price_eur" && <p>{item.price_eur} EUR</p>}
                        </div>
                    </div>
                </Link>

                <div className={css.productText}>
                    {locale == "am" && <h2>{item.titleHy}</h2>}
                    {locale == "ru" && <h2>{item.titleRu}</h2>}
                    {locale == "en" && <h2>{item.titleEn}</h2>}

                    {/*<h3>Medium arrangement</h3>*/}
                </div>
                <div className={css.productClick}>
                    <div className={css.productClickSpan}>
                        <Button onClick={handleClick("Successfully")}>
                            {/*vor mecananq, shutenq helnelu qnic, vor kyanqy erkar tva -> Gusan Arman*/}
                            <div
                                // className={css.basket_svg}
                                className={`${basketItems?.find(i => i.id == item.id) || inBasket ? css.activeBasket : css.basket_svg}`}
                                onClick={() => {
                                    setBasket(item, "Message B")
                                    setInBasket(true)
                                    setChoosen(!choosen)
                                    {
                                        !choosen && Swal.fire({
                                            position: 'center',
                                            icon: 'success',
                                            title: t("saved"),
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                    }

                                }}>
                                {/*{*/}
                                {/*    choosen*/}
                                {/*        ? <RedBasket/>*/}
                                {/*        : <Basket/>*/}
                                {/*}*/}
                                <Basket/>
                            </div>
                        </Button>
                        {/*<img*/}
                        {/*    // style={{filter:"invert(1)"}}*/}
                        {/*    src={love}*/}
                        {/*    className={`${tooglee.length > 0 ? css.setImg : ""}`}*/}
                        {/*    onClick={ToggleState(item, item.id)}*/}
                        {/*    alt={"img"}*/}

                        {/*/>*/}

                        <div
                            // className={css.basket_svg}
                            className={`${swishItems?.find(i => i.id == item.id) || like ?  css.activeBasket : css.basket_svg }`}
                            onClick={ToggleState(item, item.id)}>

                            <Love/>

                        </div>

                    </div>
                </div>
            </div>
            <div>
                <Snackbar
                    key={messageInfo ? messageInfo.key : undefined}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    TransitionProps={{onExited: handleExited}}
                    message={messageInfo ? messageInfo.message : undefined}
                    action={
                        <React.Fragment>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                UNDO
                            </Button>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{p: 0.5}}
                                onClick={handleClose}
                            >
                                {/*<CloseIcon />*/}
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div>
        </Col>
    );
};

export default HomeItem;
