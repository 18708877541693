import React, { useEffect } from "react";
import css from "./homeTwo.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { homeInfoAc } from "../../../Redux/Actions/banersAction";
import homeTwothree from "../../../Utils/images/homeTwothree.svg";

const HomeTwo = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.langReducer.lang);
  const info = useSelector((state) => state.aboutReducer.info);

  useEffect(() => {
    dispatch(homeInfoAc());
  },[]);


  return (
    <div className={css.bgHomeTwo}>
      <Container>
        <Row className="justify-content-md-center">
          {
                       info !== null && info.length && info.map((i,index)=>{
                            return (
                                <Col lg={4} md={4} xs={12} key={i.id}>

                                <div className={css.colOne}>
                                    <img
                                        src={homeTwothree}
                                        alt="Picture of the author"
                                        width={140}
                                        height={140}
                                    />

                                    {locale == "am" && <h2>{i.titleHy}</h2>}
                                    {locale == "ru" && <h2>{i.titleRu}</h2>}
                                    {locale == "en" && <h2>{i.titleEn}</h2>}
        
                                    {locale == "am" && <p>{i.descriptionHy}</p>}
                                    {locale == "ru" && <p>{i.descriptionRu}</p>}
                                    {locale == "en" && <p>{i.descriptionEn}</p>}
                                </div>
        
                            </Col>
                            )
                        })
                    }
        </Row>
      </Container>
    </div>
  );
};

export default HomeTwo;
