export const JWT_TOKEN = "kkjafeljai&*&#$nafnakj";
// export const url = "http://localhost:5000";
// export const adminUrl = "http://localhost:3001";
// export const myUrl = "http://localhost:3000";
export const url = 'https://back.flower.am'
//export const url = 'http://45.93.138.35:5000'
// export const myUrl = 'http://75.119.142.57:3000'
// export const adminUrl = 'http://75.119.142.57:3001'
//export const url = 'http://45.93.138.35:5000'
export const IdramID = "110001504";
export const SecretKey = "UwuFvCCDudej77EUn6BdgNpjqKacLSumk4cwP5";

export const myUrl = 'https://flower.am'
export const adminUrl = 'https://api.flower.am'
