import axios from "axios";
import {url} from '../../config'

export const GET_ABOUT = "GET_ABOUT"
export const GET_SERVICES_PAG = "GET_SERVICES_PAG"
export const GET_SIGLE = "GET_SIGLE"



export const getSingleProductThunk = (id) => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/product/single`, {
            params: {
                id
            },
        })
        dispatch({
            type: GET_SIGLE,
            payload: response.data
        })
    }
}

export const getAboutThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/about/`);
        dispatch({
            type: GET_ABOUT,
            payload: response.data,
        });
    };
}

export const getServicesThunk = (page) => {
    return async (dispatch) => {
        const response = await axios.get(`${url}/api/v1/services/`, {
            params: {page},
          });
        dispatch({
            type: GET_SERVICES_PAG,
            payload: response.data,
        });
    };
}

