import React, {useEffect, useState} from "react";
import css from "../Product/product.module.css";
import {Link} from "react-router-dom";
import {Col} from "react-bootstrap";
import {
    swishDataGet,
    swishRemoveDataGet,
} from "../../Redux/Actions/productActions";
import {useDispatch, useSelector} from "react-redux";
import {useCart} from "react-use-cart";
import Button from "@mui/material/Button";
import {AiOutlineEuro} from "react-icons/ai";
import love from "../../Utils/images/love.svg";
import basket from "../../Utils/images/basket.svg";
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";
import {type} from "@testing-library/user-event/dist/type";

const Item = ({item}) => {
    const locale = useSelector(state => state.langReducer.lang);
    const {addItem} = useCart();
    const val = useSelector((state) => state.serviceReducers.valutes);
    const thisValute = val.key;
    const [state, setState] = useState(false);
    const [todo, setTodo] = useState(false);
    const [swishItems,setSwishItems] = useState()
    const [inBasket,setInBasket] = useState(false)
    const [basketItems,setBasketItems] = useState()
    const [img, setImages] = useState();
    const tooglee = useSelector((state) => state.HomeReducers.swishData);
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [like,setLike] = useState(false)
    const ToggleState = (item, id) => () => {
        setLike(!like)
        setState(!state);
        !like ? dispatch(swishDataGet(item)) : dispatch(swishRemoveDataGet(id));
        !like &&  Swal.fire({
            position: 'center',
            icon: 'success',
            title: t("saved"),
            showConfirmButton: false,
            timer: 1500
        })
        // : Swal.fire({
        //         position: 'center',
        //         icon: 'success',
        //         title: t("canceled"),
        //         showConfirmButton: false,
        //         timer: 1500
        //     })
    };

    const [snackPack, setSnackPack] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState(undefined);

    useEffect(() => {
        setImages(item.images);
        setImages(item.images?.substring(0, item.images.length)?.split(",")[0]);
    }, [item]);

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({...snackPack[0]});
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }

        if (tooglee.length) {
            let x = tooglee.find((i) => i == item.id);
            if (x) {
                setTodo(true);
            }
        }

        // let x = JSON.parse(localStorage.getItem('swish')).find(i => i.id == item.id)
        // x ? setTodo(true) : setTodo(false)

        // setSwishItems(localStorage.getItem("swish").substr(1,localStorage.getItem("swish").length))
    }, [snackPack, messageInfo, open, tooglee]);

    useEffect(()=>{
        setSwishItems(JSON.parse(localStorage.getItem("swish")))
        setBasketItems(JSON.parse(localStorage.getItem("react-use-cart")).items)
    },[inBasket,like])


    const handleClick = (message) => () => {
        setSnackPack((prev) => [...prev, {message, key: new Date().getTime()}]);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    return (
        <Col key={item.id} lg={4} md={6} xs={12}>
            <div className={css.mainProduct}>
                <Link to={"/product/" + item.id}>
                    <div className={css.productDiv}>
                        <img src={img} alt="" height={300} width={290}/>
                        <div className={css.www}>
                            <h2>WWW.FLOWER.AM</h2>
                        </div>
                        <div className={css.productPrice}>
                            {thisValute == "price_amd" && <p>{item.price_amd}  ֏</p>}
                            {thisValute == "price_eur" && (
                                <p>
                                    {item.price_eur} <AiOutlineEuro/>
                                </p>
                            )}
                            {thisValute == "price_usd" && <p>{item.price_usd} $ </p>}
                            {thisValute == "price_ru" && <p>{item.price_rub} rub</p>}
                        </div>
                    </div>
                </Link>

                {/*<div className={css.productText}>*/}
                {/*    <h2>{locale == "am" ? item.availabilityHy : locale == "ru" && item.availabilityRu*/}
                {/*        ? locale == "en" && item.availabilityEn : item.availabilityHy}</h2>*/}
                {/*</div>*/}

                <div className={css.productText}>
                    {locale == "am" && <h2>{item.titleHy}</h2>}
                    {locale == "ru" && <h2>{item.titleRu}</h2>}
                    {locale == "en" && <h2>{item.titleEn}</h2>}

                    {/*<h3>Medium arrangement</h3>*/}
                </div>
                <div className={css.productClick}>
                    <Button onClick={handleClick("Successfully")}>
                        <img
                            src={basket}
                            alt="img"
                            onClick={() => {
                                addItem({...item, price: item.price_amd})
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: t("saved"),
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                setInBasket(true)
                            }}
                            className= {`${basketItems?.find(i => i.id == item.id) || inBasket ?  css.activeBasket : css.basketImg }`}
                        />
                    </Button>
                    <img
                        src={love}
                        alt="img"
                        className={`${swishItems?.find(i => i.id == item.id) || like ?  css.activeBasket : css.loveInm }`}
                        onClick={ToggleState(item, item.id)}
                        // style={{filter: "invert(1)",bac}}
                    />
                </div>
            </div>
            <div>
                {/*<Snackbar*/}
                {/*    key={messageInfo ? messageInfo.key : undefined}*/}
                {/*    open={open}*/}
                {/*    autoHideDuration={6000}*/}
                {/*    onClose={handleClose}*/}
                {/*    TransitionProps={{onExited: handleExited}}*/}
                {/*    message={messageInfo ? messageInfo.message : undefined}*/}
                {/*    action={*/}
                {/*        <React.Fragment>*/}
                {/*            <Button color="secondary" size="small" onClick={handleClose}>*/}
                {/*                UNDO*/}
                {/*            </Button>*/}
                {/*            <IconButton*/}
                {/*                aria-label="close"*/}
                {/*                color="inherit"*/}
                {/*                sx={{p: 0.5}}*/}
                {/*                onClick={handleClose}*/}
                {/*            >*/}
                {/*                /!* <CloseIcon /> *!/*/}
                {/*            </IconButton>*/}
                {/*        </React.Fragment>*/}
                {/*    }*/}
                {/*/>   */}
            </div>
        </Col>
    );
};

export default Item;
