import { GET_ABOUT, GET_SIGLE } from "../Actions/aboutAction";
import { GET_HOME_INFO } from "../Actions/banersAction";

const initialState = {
  aboutText: [],
  allServices: [],
  single: null,
  info: null,
};

export const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ABOUT:
      return {
        ...state,
        aboutText: [...state.aboutText, action.payload],
      };
    case GET_SIGLE:
      return {
        ...state,
        single: action.payload,
      };
    case GET_HOME_INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      return state;
  }
};
