import React from "react";
import {Route, Routes} from "react-router-dom";
import {detailsPages, paymentPages, profileViews, publickPages, sidePages, termPages} from "./routes";

const Pages = () => {

    return (<Routes>
        {publickPages.map((i) => {
            return <Route path={i.path} element={<i.Component/>} key={i.id}/>;
        })}

        {detailsPages.map((i) => {
            return <Route path={i.path} element={<i.Component/>} key={i.id}/>;
        })}

        {paymentPages.map((i) => {
            return <Route path={i.path} element={<i.Component/>} key={i.id}/>;
        })}

        {sidePages.map((i) => {
            return <Route path={i.path} element={<i.Component/>} key={i.id}/>;
        })}
        {termPages.map((i) => {
            return <Route path={i.path} element={<i.Component/>} key={i.id}/>;
        })}

        {profileViews.map((i) => {
            return <Route path={i.path} element={<i.Component/>} key={i.id}/>;
        })}
	
    </Routes>);
};
export default Pages;
