export const serviceData = [
    {
        id: 1,
        productName: 'Rose',
        price: '12',
        productInStock: true,
        productModel: '004024356413',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Arrangement'
    },
    {
        id: 2,
        productName: 'Orchid',
        price: '150',
        productInStock: true,
        productModel: '0040645645213',
        productImage1: 'https://pngimg.com/uploads/bouquet/bouquet_PNG55.png',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Bouquet'
    },
    {
        id: 3,
        productName: 'Chrysanthemum',
        price: '1150',
        productInStock: true,
        productModel: '0040267876813',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Single'
    },
    {
        id: 4,
        productName: 'Peruvian Lily',
        price: '85',
        productInStock: true,
        productModel: '0056887640213',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Bouquet'
    },
    {
        id: 5,
        productName: 'Hyacinth',
        price: '55',
        productInStock: false,
        productModel: '7567657657',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Arrangement'
    },
    {
        id: 6,
        productName: 'THE HENNA',
        price: '13',
        productInStock: true,
        productModel: '0045y54y0213',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Single'
    },
    {
        id: 7,
        productName: 'FREESIA',
        price: '1800',
        productInStock: false,
        productModel: '004243240213',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Arrangement'
    },
    {
        id: 8,
        productName: 'HYACINTH',
        price: '8000',
        productInStock: true,
        productModel: '0040436546213',
        productImage1: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1643687977/vendor/3359/catalog/product/2/0/20170929060229_file_59ce8ab51100d.jpg',
        productImage2: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBHJjL0DbaHaVEUJAJ7673X8jteLGc15qny7eX739Hl8w2qARuVU1ePj63aDO4LiuOVZE&usqp=CAU',
        productImage3: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378420/vendor/2853/catalog/product/2/0/20210421053521_file_608062591ef84_608062bf91d7d..jpg',
        productImage4: 'https://www.gannett-cdn.com/presto/2021/05/24/NOKL/bbca8c38-ac89-47be-a479-2cb998746882-RoseBouquet_TJoes_20.jpeg',
        productImage5: 'https://asset.bloomnation.com/c_pad,d_vendor:global:catalog:product:image.png,f_auto,fl_preserve_transparency,q_auto/v1644378418/vendor/2853/catalog/product/2/0/20210224081600_file_6036b400cafed_6036b439dea49..jpg',
        productType: 'Single'
    },
]