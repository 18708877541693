import React, { useEffect, useState } from "react";
import css from "./aboutUs.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAboutThunk } from "../../Redux/Actions/aboutAction";
import { getAboutBannerThunk } from "../../Redux/Actions/banersAction";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.langReducer.lang);
  const aboutText = useSelector((state) => state?.aboutReducer.aboutText);
  const banner = useSelector((state) => state.banerReducer.aboutBanner);
  const [text, setText] = useState();
  const {t} = useTranslation()
  useEffect(() => {
    dispatch(getAboutThunk());
    dispatch(getAboutBannerThunk());
  }, []);

  useEffect(() => {
    setText(aboutText[0]);
  }, [aboutText]);

  useEffect(() => {
    // console.log = console.warn = console.error = () => {};
    console.clear()
  },[] );
  return (
    <div>
      {banner && (
        <div className={css.aboutUsBg} style={{
          backgroundImage: `url(${banner.image})`
        }}>
          {/* <h1>Avenue Des Fleurs</h1> */}
          {locale == "am" && <p>{banner && <h1>{banner.titleHy}</h1>}</p>}
          {locale == "ru" && <p>{banner && <h1>{banner.titleRu}</h1>}</p>}
          {locale == "en" && <p>{banner && <h1>{banner.titleEn}</h1>}</p>}
        </div>
      )}

      <Container>
        <Row className="mt-5 mb-5">
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className={css.aboutH1}>
                <h2>{t("about")}</h2>
              </div>
              <div className={css.aboutP}>
                {locale == "am" && (
                  <p >{text && text.map((i) => i.descriptionHy)}</p>
                )}
                {locale == "ru" && (
                  <p>{text && text.map((i) => i.descriptionRu)}</p>
                )}
                {locale == "en" && (
                  <p>{text && text.map((i) => i.descriptionEn)}</p>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={css.aboutBg2}></div>
    </div>
  );
};

export default AboutUs;
