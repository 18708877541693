import React, { useEffect, useState, Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { serviceGet } from "../../Redux/Actions/serviceActions";
import css from "./service.module.css";
import { Link } from "react-router-dom";
import { BiHomeAlt } from "react-icons/bi";
import { getServicesThunk } from "../../Redux/Actions/aboutAction";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";

const Service = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.serviceReducers.serviceData);
  const allServces = useSelector((state) => state.serviceReducers.all);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(serviceGet());
    console.clear();
  }, []);

  useEffect(() => {
    dispatch(getServicesThunk(page));
  }, [page]);

   const Artists = React.lazy(() => import("./serviceItem"));

  return (
    <div>
      <Container>
        <Row className="justify-content-md-start mt-3 mt-3">
          <Col lg={12} md={12} xs={12} className="mt-2">
            <div className={css.productHome}>
              <Link to="/">
                <BiHomeAlt />
              </Link>
              <span>/</span>
              <p>{t("services")}</p>
            </div>
          </Col>

          <Suspense fallback={<Loading />}>
            {allServces.length == 0 ? (
              <Loading />
            ) : (
              allServces.length > 0 &&
              allServces[0]?.map((item) => {
                return <Artists item={item} />;
              })
            )}
          </Suspense>
        </Row>
        <p className={css.productSee} onClick={() => setPage(page + 1)}>
          {t("more")}
        </p>
      </Container>
    </div>
  );
};

export default Service;
